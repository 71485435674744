import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from 'react';

// Pages
import Landing from "./Pages/Landing/Landing";
import Home from "./Pages/Home/Home";
import LearningObjective from "./Pages/LearningObjective/LearningObjective";
import Feedback from "./Pages/Feedback/Feedback";
import Assessment from "./Pages/Assessment/Assessment";
import TermsOfService from "./Pages/Terms/TermsOfService";
import PrivacyPolicy from "./Pages/Terms/PrivacyPolicy";
import LessonPage from "./Pages/Home/LessonPage";
import EditPage from './Pages/Edit/EditPage';
import SearchPage from "./Pages/Search/SearchPage";
import AccountPage from "./Pages/Account/AccountPage";
import LessonLanding from "./Pages/Landing/LessonLanding";

// Analytics
import posthog from 'posthog-js'

function App() {

  posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />}/>
        <Route path="/home" element={<Home />}/>
        <Route path="*" element={<Home />} />
        <Route path="/learningobjective" element={<LearningObjective />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/l" element={<LessonLanding />} />
        <Route path="/l/:lessonId" element={<LessonPage />} />
        <Route path="/l/undefined" element={<LessonPage />} />
        <Route path="/e/:lessonId" element={<EditPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/account" element={<AccountPage />} />
      </Routes>
    </Router>
  );
}

export default App;
