import React, { useState, useEffect } from "react";
import LFBreadcrumb from "./LFBreadcrumb";
import OpenAI from "openai";
import { useNavigate } from "react-router-dom";
import Confetti from 'react-confetti'

// Slide helpers
import { 
    lessonFlowSlide2,
    lessonFlowSlide3,
    lessonFlowSlide4,
    lessonFlowSlide5,
    lessonFlowSlide6, 
    lessonFlowSlide7,
    lessonFlowSetAnticipatorySet,
    lessonFlowSetObjectiveAndPurpose,
    lessonFlowSetModeling,
    lessonFlowSetInput,
    lessonFlowSetFinished,
    lessonFlowSetConsiderations,
    setLessonAsComplete

} from "../../../Components/Authentication/Firebase";

function LFCoordinator({lessonId, uid}){

    const [step, setStep] = useState(1);
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    function updateStep(direction){
        if (direction === 'forward'){
            if (step === 7){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step + 1);
            }
        } else if (direction === 'back'){
            if (step === 1){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step - 1);
            }
        } else {
            return;
        }
    }

    let content;
    if (step === 1) {
        content = <LFStepOne stepChangeCallback={updateStep} userId={uid} lessonId={lessonId} />;
    } else if (step === 2) {
        content = <LFStepTwo stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    } else if (step === 3) {
        content = <LFStepThree stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    } else if (step === 4){
        content = <LFStepFour stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    } else if (step === 5){
        content = <LFStepFive stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    } else if (step === 6){
        content = <LFStepSix stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    } else if (step === 7){
        content = <LFStepSeven stepChangeCallback={updateStep} userId={uid} lessonId={lessonId}/>;
    }
    else 
    {
        content = <LFStepOne stepChangeCallback={updateStep} userId={uid} lessonId={lessonId} />;
    }

    return(
        <div className="relative">
            {content}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-white">
                <LFBreadcrumb step={step} />
            </div>
        </div>
    )
}

export default LFCoordinator;

export function LFStepOne({stepChangeCallback, userId, lessonId}){

    const [considerations, setConsiderations] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function onConsiderationsChange(event){setConsiderations(event.target.value)}

    function moveFromStep(direction){

        setErrorMessage('');

        if (direction === "back"){
            // In coordinator, should return to lesson plan page
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // Check if inputs are filled out
            if (considerations === ''){
                setErrorMessage('Please fill out the field before continuing. If there are no considerations, enter N/A.')
                return;
            } else {
                // Save information to Firebase
                lessonFlowSetConsiderations(userId, lessonId, considerations);
                stepChangeCallback(direction);
            }

        } else {
            return;
        }
    }

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Lesson Flow Preparation</h5>
            <p className="font-light leading-5 mt-1">In our lesson flow preparation, we will <b>analyze your students' needs</b> and <b>identify the academic standard</b> you will be designing your lesson plan around.</p>
            
            <div class="mb-6 mt-6">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Additional considerations</label>
                <input value={considerations} onInputCapture={onConsiderationsChange} placeholder="A budget for the lesson, a new resource you want to use, etc." type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 font-light text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 font-normal">Any information that will help us map out your lesson content.</p>
            </div>

            <div className="mb-6">
                <p className="text-red-950 font-normal">{errorMessage}</p>
            </div>

            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LFStepTwo({stepChangeCallback, userId, lessonId}){

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);

        } else if (direction === "forward") {
            // No audience generated
            if (lastBest === ''){
                setInstruction('Please write an Anticipatory Set before continuing.')
                return;
            } else {
                lessonFlowSetAnticipatorySet(userId, lessonId, lastBest);
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    // Getting values to answer first user input
    async function onRenderCallback(classDescription, academicStandard, learningObjective){

        // #1: Set values to use for prompt
        setAcademicStandard(academicStandard);
        setClassDescription(classDescription);
        setLearningObjective(learningObjective);

    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt

            // ANTICIPATORY SET
            let lessonFlowIntroduction = "In the instructional design process, an educator develops a 'lesson flow'. The flow includes the following components: 1. the anticipatory set (the hook), 2. objective and purpose (the overall purpose of the lesson), 3. input (the new information like discussions, readings, lectures the students will consume), 4. modeling (what the final skill/knowledge looks like), 5. checking for understanding (determine where the students are in relation to the objective, such as a formative assessment), 6. guided practice (practicing the knowledge from the lesson, 7. independent practice, and 8. closure (summative assessment or another opportunity to check for understanding)."
            let anticipatorySetIntroduction = `The anticipatory set, often referred to as the "hook" in a lesson plan within an instructional design system, is a crucial component that serves as the opening or introduction to the lesson. Its primary purpose is to engage and motivate learners, setting the stage for the upcoming instruction. Key properties of an effective anticipatory set include:

Relevance: The hook should connect the lesson's content to real-life experiences or prior knowledge of the learners, making it relatable and meaningful.

Engagement: It should capture the learners' attention and spark their curiosity, generating interest in the topic to be covered.

Clear Objective: The anticipatory set should clearly communicate the learning objectives or goals of the lesson, providing a sense of purpose and direction for the learners.

Variety: Utilizing a variety of instructional strategies, such as anecdotes, multimedia, thought-provoking questions, or demonstrations, can make the anticipatory set more engaging and appealing to different learning styles.

Alignment: It should align with the overall instructional design and seamlessly transition into the main content of the lesson, ensuring a coherent and logical flow of instruction.

In essence, the anticipatory set acts as a "hook" to captivate learners' interest, motivate them to explore the lesson's content, and create a positive and engaging learning experience.`
            let commonAnticipatorySetFailures = `Educators may fail to create an effective hook or anticipatory set for their lessons in several ways:

Lack of Relevance: When educators fail to connect the anticipatory set to the learners' prior knowledge or real-life experiences, it can lead to disinterest. Irrelevant or abstract hooks may leave students wondering why they should care about the upcoming lesson.

Overcomplication: Some educators make the mistake of making the hook too complex or lengthy. Overloading the introduction with too much information or technical jargon can overwhelm learners and deter them from engaging with the lesson.

Not Aligning with Learning Objectives: If the anticipatory set doesn't clearly align with the learning objectives of the lesson, students may struggle to see the purpose or relevance of the activities that follow. This lack of alignment can lead to confusion and disengagement.`
            let frameworkIntroduction = `${lessonFlowIntroduction}\n\n${anticipatorySetIntroduction}\n\n${commonAnticipatorySetFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating the anticipatory set for a lesson with the following academic standard: ${academicStandard}. The learning objective for the lesson is: ${learningObjective} The educator will submit their version of their hook to you, an instructional coach, and you will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For anticipatory set, I will use: ${userFeedback}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conversationContext}`;
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    useEffect(() => {
        lessonFlowSlide2(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Anticipatory Set</h5>
            <p className="font-light">The anticipatory set is the 'hook' for your lesson. It answers the question: how will you introduce, and hook, your students into the lesson?</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input placeholder="What is your hook?" value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LFStepThree({stepChangeCallback, userId, lessonId}){

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('');
    const [anticipatorySet, setAnticipatorySet] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);

        } else if (direction === "forward") {
            // No audience generated
            if (lastBest === ''){
                setInstruction('Please write an "objective and purpose" before continuing.')
                return;
            } else {
                lessonFlowSetObjectiveAndPurpose(userId, lessonId, lastBest);
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    // Getting values to answer first user input
    async function onRenderCallback(classDescription, academicStandard, learningObjective, anticipatorySet){

        // #1: Set values to use for prompt
        setAcademicStandard(academicStandard);
        setClassDescription(classDescription);
        setLearningObjective(learningObjective);
        setAnticipatorySet(anticipatorySet);

    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt

            // ANTICIPATORY SET
            let lessonFlowIntroduction = "In the instructional design process, an educator develops a 'lesson flow'. The flow includes the following components: 1. the anticipatory set (the hook), 2. objective and purpose (the overall purpose of the lesson), 3. input (the new information like discussions, readings, lectures the students will consume), 4. modeling (what the final skill/knowledge looks like), 5. checking for understanding (determine where the students are in relation to the objective, such as a formative assessment), 6. guided practice (practicing the knowledge from the lesson, 7. independent practice, and 8. closure (summative assessment or another opportunity to check for understanding)."
            let objectiveAndPurposeIntroduction = `The "objective and overall purpose" component of a lesson in an instructional design system serves as the foundational element that guides the entire instructional process. It is a clear and concise statement that outlines what learners are expected to achieve by the end of the lesson. Key properties of this component include:

Clarity: The objective should be expressed in a clear and unambiguous manner so that both educators and learners understand precisely what is expected. It should use language that is easily comprehensible to the target audience.

Specificity: Objectives should be specific, detailing the desired knowledge, skills, or behaviors learners should demonstrate. Vague objectives can lead to confusion and ineffective instruction.

Measurability: Objectives should be framed in a way that allows for assessment and measurement of whether the learning outcomes have been achieved. This typically involves using action verbs that can be observed or assessed.

Relevance: The objective should align with the overall purpose of the lesson and connect to broader learning goals or objectives within a curriculum. It should answer the question of "Why is this lesson important?"

Realistic and Achievable: Objectives should be attainable within the context of the lesson and the abilities of the learners. Unrealistic objectives can lead to frustration and demotivation.

Time-bound: The objective should specify a timeframe or deadline by which the desired outcomes should be achieved. This helps in pacing the instruction and setting expectations for learners.

Alignment: It should align with any pre-requisite knowledge or skills that learners are expected to have, ensuring a logical progression in the instructional sequence.`
            let commonObjectiveAndPurposeFailures = `Teachers may encounter several common failures when creating objectives and overall purposes for their lessons. Here are two to three of these common failures:

Lack of Clarity: One of the most frequent failures is the absence of clarity in the learning objective. If the objective is too vague or uses ambiguous language, it becomes challenging for both the teacher and the learners to understand what exactly needs to be achieved. For instance, an objective like "Understand history" is too vague and doesn't specify what aspects of history students are expected to grasp. This lack of clarity can lead to confusion and hinder effective learning.

Overloading Objectives: Teachers sometimes attempt to cover too much content within a single lesson objective. When objectives are overloaded with numerous sub-skills or topics, it can overwhelm learners, making it difficult for them to focus and master the intended outcomes. Overloaded objectives may also lead to shallow learning, as students rush through the material without gaining a deep understanding.

Ignoring the Learning Context: Failure to consider the specific learning context and the needs of the learners can result in ineffective objectives. An objective that does not align with the prior knowledge or abilities of the students can set them up for failure. For example, if a teacher assumes that students have a certain prerequisite knowledge without confirming it, the objective may be too advanced for the class, causing frustration and discouragement among learners.`
            let frameworkIntroduction = `${lessonFlowIntroduction}\n\n${objectiveAndPurposeIntroduction}\n\n${commonObjectiveAndPurposeFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating the 'objective and purpose' content section for a lesson with the following academic standard: ${academicStandard}. The learning objective for the lesson is: ${learningObjective}. The anticipatory set is: ${anticipatorySet}. The educator will submit their version of their 'objective and purpose' to you, an instructional coach, and you will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For objective and purpose, I will use: ${userFeedback}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conversationContext}`;
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    useEffect(() => {
        lessonFlowSlide3(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Objetive and Purpose</h5>
            <p className="font-light">The 'objective and purpose' is the overall purpose of teaching (and learning) from the lesson. For students, it answers the question: what is going to be accomplished and what is expected of me?</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full min-h-48 bg-gray-50 rounded-md my-6 border border-gray-300 max-h-fit">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input placeholder="What is your hook?" value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LFStepFour({stepChangeCallback, userId, lessonId}){

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('');
    const [anticipatorySet, setAnticipatorySet] = useState('');
    const [objectiveAndPurpose, setObjectiveAndPurpose] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);

        } else if (direction === "forward") {
            // No audience generated
            if (lastBest === ''){
                setInstruction('Please write an "input" before continuing.')
                return;
            } else {
                lessonFlowSetInput(userId, lessonId, lastBest);
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    // Getting values to answer first user input
    async function onRenderCallback(classDescription, academicStandard, learningObjective, anticipatorySet, objectiveAndPurpose){

        // #1: Set values to use for prompt
        setAcademicStandard(academicStandard);
        setClassDescription(classDescription);
        setLearningObjective(learningObjective);
        setAnticipatorySet(anticipatorySet);
        setObjectiveAndPurpose(objectiveAndPurpose);

    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt

            // ANTICIPATORY SET
            let lessonFlowIntroduction = "In the instructional design process, an educator develops a 'lesson flow'. The flow includes the following components: 1. the anticipatory set (the hook), 2. objective and purpose (the overall purpose of the lesson), 3. input (the new information like discussions, readings, lectures the students will consume), 4. modeling (what the final skill/knowledge looks like), 5. checking for understanding (determine where the students are in relation to the objective, such as a formative assessment), 6. guided practice (practicing the knowledge from the lesson, 7. independent practice, and 8. closure (summative assessment or another opportunity to check for understanding)."
            let inputIntroduction = `Choosing and presenting the right input material is crucial for K-12 educators to ensure effective learning outcomes. Here are some best practices to follow:

Alignment with Learning Objectives: Start by clearly defining your learning objectives for the lesson. The input material you choose should directly support these objectives. Ensure that the content is age-appropriate and relevant to the students' developmental stage.

Diverse Sources: Incorporate a variety of input sources to cater to different learning styles and preferences. Mix traditional resources like textbooks with multimedia, hands-on activities, and real-world examples to engage students and make the content more accessible.

Scaffolded Learning: Present the input material in a scaffolded manner, gradually increasing complexity. Begin with foundational concepts before moving to more advanced topics, allowing students to build on their prior knowledge.

Active Engagement: Encourage active engagement with the material. Use strategies like group discussions, interactive exercises, and problem-solving activities to foster critical thinking and application of knowledge.

Differentiation: Recognize that students have diverse learning needs and abilities. Differentiate the input material to accommodate various skill levels and provide additional resources or challenges for students who need them.

Assessment Integration: Design assessments that align with the input material, ensuring that they measure the intended learning outcomes. Assessments should reflect the variety of input sources used in the lesson.

Authenticity and Relevance: Select input material that is authentic and relevant to students' lives. When they see the real-world application of what they are learning, they are more likely to stay engaged and motivated.

Clear Organization: Present the input material in a structured and organized manner. Use headings, bullet points, and visual aids to help students navigate and comprehend the content effectively.

Feedback Loop: Create opportunities for students to receive feedback on their understanding of the input material. This could be through formative assessments, peer evaluations, or class discussions.`
            let commonInputFailures = `Teachers may encounter several common failures when choosing and teaching input content:

Lack of Alignment with Learning Objectives: One of the most significant failures is not aligning the input content with the intended learning objectives. When teachers choose material that does not directly support the educational goals of the lesson, it can lead to confusion and frustration among students, as they may not see the relevance of what they are learning to their overall education.

Overloading with Information: Providing an overwhelming amount of input material in a single lesson can be detrimental. When teachers overload students with too much information, it can result in cognitive overload, making it difficult for students to process and retain the content. This can lead to superficial learning and poor comprehension.`
            let frameworkIntroduction = `${lessonFlowIntroduction}\n\n${inputIntroduction}\n\n${commonInputFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating the 'input' content section for a lesson with the following academic standard: ${academicStandard}. The learning objective for the lesson is: ${learningObjective}. The anticipatory set is: ${anticipatorySet}. The objective and purpose is: ${objectiveAndPurpose}. The educator will submit their version of their 'input' to you, an instructional coach, and you will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For 'input', I will use: ${userFeedback}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conversationContext}`;
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    useEffect(() => {
        lessonFlowSlide4(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Input</h5>
            <p className="font-light">The 'input' is the main content you will use in your lesson to instruct your students. This is the material that helps your students achieve the intended outcome of the learning objective.</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full min-h-48 bg-gray-50 rounded-md my-6 border border-gray-300 max-h-fit">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input placeholder="What is your hook?" value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LFStepFive({stepChangeCallback, userId, lessonId}){

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('');
    const [anticipatorySet, setAnticipatorySet] = useState('');
    const [objectiveAndPurpose, setObjectiveAndPurpose] = useState('');
    const [input, setInput] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [context, setContext] = useState([]);

    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [lastBest, setLastBest] = useState('');


    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);

        } else if (direction === "forward") {
            // No audience generated
            if (lastBest === ''){
                setInstruction('Please write a "modeling" before continuing.')
                return;
            } else {
                lessonFlowSetModeling(userId, lessonId, lastBest);
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    // Getting values to answer first user input
    async function onRenderCallback(classDescription, academicStandard, learningObjective, anticipatorySet, objectiveAndPurpose, input){

        // #1: Set values to use for prompt
        setAcademicStandard(academicStandard);
        setClassDescription(classDescription);
        setLearningObjective(learningObjective);
        setAnticipatorySet(anticipatorySet);
        setObjectiveAndPurpose(objectiveAndPurpose);
        setInput(input);

    }

    // Responding to all user inputs
    async function submitUserResponse(){

        setIsLoading(true);

        // No user input
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        if (context.length === 0) { // FIRST input

            // #1: Create initial prompt

            // ANTICIPATORY SET
            let lessonFlowIntroduction = "In the instructional design process, an educator develops a 'lesson flow'. The flow includes the following components: 1. the anticipatory set (the hook), 2. objective and purpose (the overall purpose of the lesson), 3. input (the new information like discussions, readings, lectures the students will consume), 4. modeling (what the final skill/knowledge looks like), 5. checking for understanding (determine where the students are in relation to the objective, such as a formative assessment), 6. guided practice (practicing the knowledge from the lesson, 7. independent practice, and 8. closure (summative assessment or another opportunity to check for understanding)."
            let modelingIntroduction = `Selecting the appropriate model in instructional design is a crucial step in ensuring effective learning outcomes. Here are some best guidelines for deciding on the model:

Alignment with Learning Objectives: Begin by clearly defining your learning objectives. The model you choose should align seamlessly with these objectives. Consider what skills, knowledge, or behaviors you want students to acquire by the end of the lesson.

Learner-Centered Approach: Take into account the characteristics and needs of your target audience. Different learners may benefit from different models, so it's essential to choose one that resonates with your students' learning styles and preferences.

Content Complexity: Consider the complexity of the subject matter. Some topics may lend themselves better to certain instructional models. For instance, complex scientific concepts might be best taught through a hands-on experiment, while historical events may be effectively conveyed through storytelling or case studies.

Resources and Constraints: Assess the available resources, including time, technology, and materials. Ensure that the selected model is feasible within the constraints of your instructional environment. Models requiring elaborate equipment or extensive time might not be suitable for all situations.

Interactivity and Engagement: Evaluate the level of interactivity and engagement offered by the model. Active learning approaches, such as problem-based learning or group discussions, often enhance student engagement and retention. Choose a model that encourages active participation.

Assessment and Feedback: Consider how you will assess student progress and provide feedback. The chosen model should allow for meaningful assessment methods that align with your learning objectives. Additionally, it should facilitate timely and constructive feedback to support student improvement.`
            let commonModelingFailures = `Teachers can encounter several common failures when designing instructional models. Here are two to three of them:

Misalignment with Learning Objectives: One common failure is creating a model that does not align well with the intended learning objectives. This misalignment can lead to confusion and inefficiency in the classroom. For instance, if the learning objective is for students to develop problem-solving skills, but the instructional model relies heavily on passive lecture-style teaching, it can result in a disconnect between what students are expected to learn and how they are being taught.

Overlooking Diverse Learning Styles: Failing to consider the diverse learning styles and needs of students can be a significant pitfall. A one-size-fits-all approach, where a single instructional model is applied uniformly to all students, can leave some learners disengaged or struggling. Teachers need to recognize that students have different preferences and strengths when it comes to learning, and failing to accommodate these differences can hinder the effectiveness of the model.

Insufficient Adaptability: Another common failure is a lack of adaptability in the chosen model. Teachers may rigidly stick to a particular model even when it becomes evident that it's not working effectively for a specific group of students or a particular lesson. An inability to adjust the model or explore alternative teaching methods can hinder student learning and limit the teacher's ability to meet the diverse needs of their students.`
            let frameworkIntroduction = `${lessonFlowIntroduction}\n\n${modelingIntroduction}\n\n${commonModelingFailures}\n\n`

            let conversationContext = `The following is a conversation with a ${classDescription} educator creating the 'modeling' section for a lesson with the following academic standard: ${academicStandard}. The learning objective for the lesson is: ${learningObjective}. The anticipatory set is: ${anticipatorySet}. The objective and purpose is: ${objectiveAndPurpose}. The input is: ${input}. The educator will submit their version of their 'modeling' to you, an instructional coach, and you will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For 'modeling', I will use: ${userFeedback}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conversationContext}`;
            let firstMessage = {role: 'system', content: fullPrompt};

            // #2: Generate first response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-4-0613",
                messages: [firstMessage],
                temperature: 0.5,
                max_tokens: 500
            });

             // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my response, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(userFeedback);
            setUserFeedback('');

            setIsLoading(false);


        } else {    // Follow up inputs

            // Step #1: Housekeeping before rendering new response
            let feedbackForPrompt = userFeedback;
            setUserFeedback('');
            setIsLoading(true);

            // Step #2: Build next prompt
            let nextPrompt = feedbackForPrompt; // customize later on
            let nextMessage = {role: 'user', content: nextPrompt}

            // Step #3: Generate next response
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                 model: "gpt-4-0613",
                messages: [...context, nextMessage],
                temperature: 0.5,
                max_tokens: 500
            });

            // Step #4: Add response to context
            let nextNextMessage = completion.choices[0].message
            const newContext = [...context, nextMessage, nextNextMessage];
            setContext(newContext);

            // Step #5: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

            // Step #6: Store copy of last response
            setLastBest(feedbackForPrompt);
        }
    }

    useEffect(() => {
        lessonFlowSlide5(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Modeling</h5>
            <p className="font-light">The 'model' is the finished product or skill expected of your students. This is the intended outcome modeled by the learning objective.</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full min-h-48 bg-gray-50 rounded-md my-6 border border-gray-300 max-h-fit">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input placeholder="What is your hook?" value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function LFStepSix({stepChangeCallback, userId, lessonId}){

    const [anticipatorySet, setAnticipatorySet] = useState('');
    const [objectiveAndPurpose, setObjectiveAndPurpose] = useState('');
    const [input, setInput] = useState('');
    const [modeling, setModeling] = useState('');

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }


    // Getting values to answer first user input
    async function onRenderCallback(anticipatorySet, objectiveAndPurpose, input, modeling){
        setAnticipatorySet(anticipatorySet);
        setObjectiveAndPurpose(objectiveAndPurpose);
        setInput(input);
        setModeling(modeling);

        lessonFlowSetFinished(userId, lessonId);
    }

    useEffect(() => {
        lessonFlowSlide6(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Summary</h5>
            <p className="font-light">Review your work on the lesson flow.</p>
            
            <div className="mt-6">
                <h1 className="font-medium text-xl pb-1">Anticipatory Set</h1>
                <p className="pb-3 font-light text-md">{anticipatorySet}</p>
                <h1 className="font-medium text-xl pb-1">Objective and Purpose</h1>
                <p className="pb-3 font-light text-md">{objectiveAndPurpose}</p>
                <h1 className="font-medium text-xl pb-1">Input</h1>
                <p className="pb-3 font-light text-md">{input}</p>
                <h1 className="font-medium text-xl pb-1">Modeling</h1>
                <p className="pb-3 font-light text-md">{modeling}</p>
            </div>
            
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}


export function LFStepSeven({stepChangeCallback, userId, lessonId}){
    
    const [lessonIsFinished, setLessonIsFinished] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }

    // Getting values to answer first user input
    async function onRenderCallback(isFinished){
        setLessonIsFinished(isFinished);
        setLessonAsComplete(userId, lessonId);
    }

    useEffect(() => {
        lessonFlowSlide7(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            {
                lessonIsFinished ? (
                    <div></div>
                ) : (
                    <div>
                        <Confetti
                            width={windowWidth}
                            height={windowHeight}
                            tweenDuration={5}
                        />
                    </div>
                )
            }
            <h5 className="font-semibold text-3xl text-center">Congratulations! You've finished your lesson.</h5>
            <p className="font-light text-center text-lg mt-2">Feel free to go back and re-do parts at any given time.</p>
            <div className="flex flex-row justify-center items-center mt-6">
                <img className="w-64 h-64 rounded-md" src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExdnFmcjc4eHg5cGhrdmpvdjJtam9jYzBrcXhlcmxkcmd6OGt0bjYzbCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/LUhUvH4BsfE9USnlPd/giphy.gif"></img>
            </div>
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )

}