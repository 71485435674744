import React, { useState, useEffect } from 'react'
import { deleteLesson } from '../../Components/Authentication/Firebase';
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';
import './LessonCards.css';

const LessonCards = ({ lessons, isMainMenu, objectiveSelectionCallback }) => {

  // For assessment slide
  const [selectedCard, setSelectedCard] = useState(false);

  function cardWasSelected(id, date, name, description){

    setSelectedCard({
      id: id,
      name: name,
      date: date,
      description: description
    })

    if (!isMainMenu){
      objectiveSelectionCallback({
        id: id,
        name: name,
        date: date,
        description: description
      })
    }

  }

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [gap, setGap] = useState(5);
  const [cols, setCols] = useState(3);

  // const cols = viewportWidth >= 300 && viewportWidth <= 1000 ? "2" : "3";
  // const gap = viewportWidth >= 300 && viewportWidth <= 1000 ? "3" : "5";

  function returnWindow(){
    if (gap === 5 && cols === 3){
      return(
        <div className={`grid grid-cols-4 gap-x-3 gap-y-3`}>
          {lessonCards}
        </div>
      )
    } else{
      return(
        <div className={`grid grid-cols-2 gap-x-3 gap-y-3`}>
        {lessonCards}
        </div>
      )
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      if (window.innerWidth >= 300 && window.innerWidth <= 1100){
        setGap(3);
        setCols(2);
      } else {
        setGap(5);
        setCols(3);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!lessons) {
    return <div></div>;
  }

  function parseLessonTitle(title){
    let safeLesson = title.replace("_", ".");
    return safeLesson;
  }

  function deletePost(lessonId){
    const signedIn = checkAuthStatus();
    deleteLesson(signedIn.uid, lessonId);
  }

    const lessonCards = Object.entries(lessons).map(([lessonId, lesson]) => (
        <div 
          key={lessonId} 
          className={`bg-white px-4 py-4 rounded-md mr-10 hover:shadow-md ease-in-out duration-200 hover:cursor-pointer ${selectedCard.id === lessonId && !isMainMenu ? 'drop-shadow-2xl shadow-purple' : ''}`}
          onClick={() => cardWasSelected(lessonId, lesson.lesson_date, lesson.lesson_name, lesson.lesson_description)}
        >
          <div className='flex flex-row justify-between'>
              <h1 className='font-semibold text-xl'>{parseLessonTitle(lesson.lesson_name)}</h1>
              {
                isMainMenu && (
                  <button onClick={() => deletePost(lessonId)}>
                    <span class="material-symbols-outlined text-gray-600 hover:text-gray-800 ease-in-out duration-100 hover:cursor-pointer">delete</span>
                  </button>
                )
              }
          </div>
          <h1 className='font-light text-sm'>{lesson.lesson_date}</h1>
          <p className='font-normal text-md mt-1'>{lesson.lesson_description}</p>
        </div>
  ));

  return (
    <div className=' ml-10 mr-10 drop-shadow-md'>
      {returnWindow()}
      {/* {console.log(`${cols}  ${gap} `)}
      {console.log(lessonCards)} */}
    </div>
  )
};

export default LessonCards;