import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';

import BuilderNavBar from "../LearningObjective/BuilderNavBar";
import AssessmentBrainstorm from "./InputCards/AssessmentBrainstorm";
import AssessmentInformation from "./InputCards/AssessmentInformation";
import ChooseLearningObjective from "./InputCards/ChooseLearningObjective";
import FinalAssessment from "./InputCards/FinalAssessment";

function Assessment(){

    const navigate = useNavigate('');
    const [currentInputCard, setCurrentInputCard] = useState(0);
    const [uid, setUid] = useState('');
    const [lessonTitle, setLessonTitle] = useState('')

    // Slide 1
    const [learningObjective, setLearningObjective] = useState('');

    // Slide 2
    const [previousAssessmentTechniques, setPreviousAssessmentTechniques] = useState('');
    const [successfulAssessments, setSuccessfulAssessments] = useState('');
    const [resourcesForLesson, setResourcesForLesson] = useState('');

    function handleLearningObjective(response){
        setLearningObjective(response);
        console.log(response);
    }

    function handlePreviousAssessmentTechniques(response){
        setPreviousAssessmentTechniques(response);
    }

    function handleSuccessfulAssessments(response){
        setSuccessfulAssessments(response);
    }

    function handleResourcesForLesson(response){
        setResourcesForLesson(response);
    }

    // ### To prevent people from just going to assessment ###
    // useEffect(() => {
    //     const signedIn = checkAuthStatus();
    //     if (signedIn === null){
    //         navigate('/')
    //     } else {
    //         setUid(signedIn.uid);
    //     }
    // })

    function handleLessonTitle(response){
        setLessonTitle(response);
        console.log(`${lessonTitle}`)
    }

    function mountInputCards(){
        if (currentInputCard === 0){
            return <ChooseLearningObjective
                setLearningObjectiveValue={handleLearningObjective}
             />
        } else if (currentInputCard === 1) {
            return <AssessmentInformation 
                setPrevAssessmentValue={handlePreviousAssessmentTechniques}
                setSuccessfulAssessmentsValue={handleSuccessfulAssessments}
                setResourcesForLessonValue={handleResourcesForLesson}
            />
        } else if (currentInputCard === 2) {
            return <AssessmentBrainstorm
                previousAssessmentTechniques={previousAssessmentTechniques}
                successfulAssessments={successfulAssessments}
                resourcesForLesson={resourcesForLesson}
                learningObjective={learningObjective}
             />
        }
         else {
            return <ChooseLearningObjective />
        }
    }

    function navigateBackwards(){
        if (currentInputCard === 0){
            navigate('/home');
        } else if (currentInputCard === 1) {
            setCurrentInputCard(0);
        } else if (currentInputCard === 2) {
            setCurrentInputCard(1); 
        } else {
            setCurrentInputCard(0);
        }
    }   

    function navigateForwards(){
        if (currentInputCard === 0){
            setCurrentInputCard(1);
        } else if (currentInputCard === 1) {
            setCurrentInputCard(2);
        } else if (currentInputCard === 2) {
            setCurrentInputCard(0);
        }
        else {
            setCurrentInputCard(0);
        }
    }

    return(
        <div>
            <BuilderNavBar sendLessonTitle={handleLessonTitle} isLearningObjectiveBuilder={false} />
            <div className='bg-[#FAF7F3] h-screen'>
                {/* Instruction section */}
                <div className="pb-8 pt-8 border-solid border-gray drop-shadow-sm">
                    <h1 className="ml-14 mr-14 font-bold text-2xl mt-2">Step #2: Assessment Brainstorm</h1>
                    {
                        currentInputCard === 0 ? (
                            <div>
                                <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">The second step in developing a high-quality lesson plan is designing an assessment that accurately evaluates your lesson's learning objective. Select a learning objective to begin brainstorming assessment ideas.</h1>
                            </div>
                        ) :
                        currentInputCard === 1 ? (
                            <div>
                                <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">Here, we will learn more about your classroom. To create a unique assessment, help me understand what you've tried in the past and what you are looking to do for this section.</h1>
                            </div>
                        ) :
                        currentInputCard === 2 ? (
                            <div>
                                <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">Great! With this information, let's generate some assessment ideas. You have two tools: refresh the assessment idea or provide written feedback about the assessment. Once you are satsified, you can save your assessment.</h1>
                            </div>
                        ) : null
                    }
                </div>

                {/* Fill-out section */}
                {mountInputCards()}

                {/* Navigate forward and backward */}
                <div className="ml-14 mt-6 flex flex-row justify-start">
                    <button className="border border-gray border-solid rounded-md" onClick={navigateBackwards}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">west</span>
                    </button>
                    <button className="border border-gray border-solid ml-3 rounded-md" onClick={navigateForwards}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">east</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Assessment;