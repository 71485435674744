import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from '../../../Components/Authentication/AuthDetails';
import { getTeacherLessons } from '../../../Components/Authentication/Firebase';
import LessonCards from '../../Home/LessonCards';

function ChooseLearningObjective({setLearningObjectiveValue}){

    const [learningObjective, setLearningObjective] = useState('');


    const [lessons, setLessons] = useState({});
    const [uid, setUid] = useState('');
    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigate('/')
        } else {
            setUid(signedIn.uid);
            getTeacherLessons(signedIn.uid, (data) => {
                if (data === null) {
                    setLessons({})
                } else {
                    const jsonLessons= JSON.parse(data);
                    setLessons(jsonLessons);
                }
            });
        }
    }, [])
    
    const navigate = useNavigate('');
    function startNewLesson(){
        navigate('/learningobjective')
    }

    function setLearningObjectiveForLesson(response){
        setLearningObjective(response);
        setLearningObjectiveValue(response);
    }
    
    return(
        <div>
            <h1 className="mt-4 mb-6 text-center text-xl font-semibold">Choose the learning objective for your assessment.</h1>
            <div>
                {lessons != null ? (
                    <>
                    <LessonCards lessons={lessons} isMainMenu={false} objectiveSelectionCallback={setLearningObjectiveForLesson} />
                    <h1 className='text-center mt-8 text-md font-normal'>or</h1>
                    </>
                ) : null}
            </div>
            <div className="flex flex-row justify-center items-center">
                 <button
                    className='mt-5 border border-solid border-gray bg-black text-white px-3 py-2 rounded-md hover:shadow-md ease-in-out duration-200'
                    onClick={startNewLesson}
                    >
                    Create lesson plan
                </button>
            </div>
        </div>
    )
}

export default ChooseLearningObjective;