import React from "react";
import { useNavigate } from "react-router-dom";

function LessonCard({uid, lid, title, date, description, preLessonSet, learningObjectiveSet, assessmentSet, lessonFlowSet}){
    
    const navigate = useNavigate('');
    function navigateToLesson(){
        navigate(`/l/${lid}`)
    }
    
    return(
        <div 
        onClick={navigateToLesson}
        className='mb-4 max-w-md p-6 bg-white border border-gray-200 rounded-lg shadow hover:cursor-pointer duration-200 hover:shadow-md'>
            <div className='flex flex-col justify-start'>
                <div className='flex flex-row justify-between items-center'>
                    <h5 className='mb-2 text-2xl font-bold tracking-tight text-gray-900'>{title}</h5>
                    <p className='mb-2 font-light text-sm text-gray-700'>{date}</p>
                </div>
                <p className='font-normal text-gray-700'>{description}</p>
                <div className='flex flex-row justify-start mt-3'>
                    <div className='flex flex-row justify- items-center w-40'>
                        <p className='font-light text-sm text-gray-700 mr-3'>Pre-Lesson</p>
                        <span className={preLessonSet ? "material-symbols-outlined text-green-800" : "material-symbols-outlined text-yellow-800"}>
                            {preLessonSet ? "check_circle" : "error"}
                        </span>
                    </div>
                    <div className='flex flex-row justify-between items-center w-40'>
                        <p className='font-light text-sm text-gray-700'>Learning Objective</p>
                        <span className={learningObjectiveSet ? "material-symbols-outlined text-green-800" : "material-symbols-outlined text-yellow-800"}>
                            {learningObjectiveSet ? "check_circle" : "error"}
                        </span>
                    </div>
                    <div>

                    </div>
                </div>
                <div className='flex flex-row justify-start mt-1'>
                    <div className='flex flex-row justify-start items-center w-40'>
                        <p className='font-light text-sm text-gray-700 mr-2'>Assessment</p>
                        <span className={assessmentSet ? "material-symbols-outlined text-green-800" : "material-symbols-outlined text-yellow-800"}>
                            {assessmentSet ? "check_circle" : "error"}
                        </span>
                    </div>
                    <div className='flex flex-row justify-between items-center w-40'>
                        <p className='font-light text-sm text-gray-700 mr-2'>Lesson Flow</p>
                        <span className={lessonFlowSet ? "material-symbols-outlined text-green-800" : "material-symbols-outlined text-yellow-800"}>
                            {lessonFlowSet ? "check_circle" : "error"}
                        </span>
                    </div>
                    <div>

                    </div>
                </div>
                {/* <div className='flex flex-row justify-end items-center mt-2'>
                    <span class="material-symbols-outlined text-gray-800 hover:text-gray-600 duration-200 ease-in-out">ios_share</span>
                </div> */}
            </div>
        </div>
    )
}

export default LessonCard;