import React from 'react';
import { auth } from '../../Components/Authentication/Firebase';
import { signOut } from 'firebase/auth';
import { useNavigate } from "react-router-dom";

function FeedbackNavBar(){

    const navigate = useNavigate('');
    
    function navigateHome(){
        navigate('/home');
    }
    
    function signUserOut(){
        signOut(auth).then(()=> {
            console.log('signed out successfully');
            navigate('/');
        }).catch((error) => {
            console.log(error)
        })
}
    
    return(
        <div className='flex flex-row justify-between h-16 w-screen items-center bg-white'>
            {/* Empty left side, maybe a logo */}
            <div>
                <button className="hover:cursor-pointer" onClick={navigateHome}>
                    <div className="ml-10">
                        <span class="material-symbols-outlined text-3xl text-gray-600 hover:text-gray-900 ease-in-out duration-200">home</span>
                    </div>
                </button>
            </div>

            {/* Feedback and sign out */}
            <div className='flex flex-row justify-around'>
                <button onClick={signUserOut}>
                    <h1 className='ml-10 mr-10 font-normal text-gray-600 hover:text-gray-900 ease-in-out duration-200'>Sign Out</h1>
                </button>
            </div>
        </div>
    )
}
export default FeedbackNavBar;