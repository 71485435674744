import React from "react";
import { useNavigate } from "react-router-dom";

function OutsideNavBar(){
    
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }
    
    return(
        <div>
            <div className='flex flex-row justify-between h-16 w-screen items-center bg-[#2CA58D]'>
            <div>
                <button onClick={() => navigateTo('/')}>
                    <span class="ml-6 material-symbols-outlined text-3xl text-white hover:text-gray-200 hover:cursor-pointer ease-in-out duration-200">home</span>
                </button>
            </div>

            {/* Feedback and sign out */}
            <div className='flex flex-row justify-around space-x-10'>

            </div>
        </div>
        </div>
    )
}

export default OutsideNavBar;