import React, { useState } from 'react';

// Authentication components
import { auth } from './Firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { Navigate } from "react-router-dom";

// Styling
import './SignIn.css';


function SignInWithCredentials(){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('')
    const [signedIn, setSignedIn] = useState(false);
    const [action, setAction] = useState('');

    if (signedIn){
        return <Navigate to="/home" />;
    }

    function handleAction(actionType){
        setAction(actionType);
    };

    function signIn(e){
        e.preventDefault();
        setError('');

        if (email === "" || password === ""){
            return;
        }

        if (action === "login"){
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setError("Success!")
                setSignedIn(true);
            }).catch((error)=> {
                if (error.message === "Firebase: Error (auth/wrong-password)."){
                    setError('Wrong password. Please try again.');
                }
                else if (error.message === "Firebase: Error (auth/user-not-found)."){
                    setError("User not found.");
                }
                else if (error.message === "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests)."){
                    setError("Too many failed login attempts. Please contact help@classroomgpt.app or try logging in another way.")
                }
                else {
                    setError("Unknown error. Please contact help@classroomgpt.app or try logging in another way.")
                }
            })
        } else if (action === "signup") {

            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setError("Success!");
                setSignedIn(true);
            }).catch((error)=> {
                if (error.message === "Firebase: Password should be at least 6 characters (auth/weak-password)."){
                    setError("Password should be at least 6 characters.")
                }
                else if (error.message === "Firebase: Error (auth/email-already-in-use)."){
                    setError("Email is already in use. Please sign in.")
                }
                else if (error.message === "Firebase: Error (auth/invalid-email)."){
                    setError("Invalid email. Please try another email.")
                }
                else {
                    setError("Unknown error. Please contact help@classroomgpt.app or try logging in another way.")
                }
            })
        } else {
            return;
        }
    }

    return (
        <div className='sign-in-container w-64'>
            <form onSubmit={signIn}>
                <h2 className='text-left mb-1 font-normal'>Email</h2>
                <input className='text-sm focus:border-[#84bc9cff] focus:outline-none focus:ring-1 mb-2 w-full rounded-md px-2 py-2 font-light border border-solid border-gray' onChange={((e) => setEmail(e.target.value))} placeholder='steve@lausd.edu' type="email" value={email}></input>
                <h2 className='text-left mt-2 mb-1 font-normal'>Password</h2>
                <input className='text-sm focus:border-[#84bc9cff] focus:outline-none focus:ring-1 mb-2 w-full rounded-md px-2 py-2 font-light border border-solid border-gray' onChange={(e) => setPassword(e.target.value)} placeholder='educationisgreat123' type="password" value={password}></input>
                <div className='flex flex-row justify-between items-center mt-2'>
                    <button type="submit" className='text-sm w-full mr-2 bg-[#2C2C2C] text-[#FFFFFF] px-3 py-2 rounded-md hover:bg-[#4b4b4b] ease-in-out duration-200 border border-solid border-gray' onClick={() => handleAction('login')}>Log In</button>
                    <button type="submit" className='text-sm w-full bg-[#2C2C2C] text-[#FFFFFF] px-3 py-2 rounded-md hover:bg-[#4b4b4b] ease-in-out duration-200 border border-solid border-gray' onClick={() => handleAction('signup')}>Sign Up</button>     
                </div>
                <h1 className='mt-4 text-center font-light text-red-900'>{error}</h1>    
            </form>
        </div>
    )
}

export default SignInWithCredentials;