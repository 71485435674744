import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// Authentication components
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';
import { getTeacherLessons, initializeNewLessonPlan } from '../../Components/Authentication/Firebase';

// Miscellaneous components
import NavBar from '../Home/NavBar';
import LessonCard from '../Home/LessonCard';
import LoadingCardsComponents from './LoadingCardsComponent';

function LessonLanding(){

    const [uid, setUid] = useState('');
    const [lessons, setLessons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    document.title = `Lessons • ClassroomGPT`

    // Delay load by 250 miliseconds
    const delay = setTimeout(() => {
      setIsLoading(false);
    }, 400);


    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            setUid(signedIn.uid);
            getTeacherLessons(signedIn.uid, (data) => {
                if (data === null || data === 'null') {
                    setLessons({})
                } else {
                    const jsonLessons= JSON.parse(data);
                    const lessonsArray = Object.values(jsonLessons);
                    
                    // MVP lessons that testers may still have in their Firebase object
                    const filteredLessonsArray = lessonsArray.filter(lesson => !lesson.hasOwnProperty("lesson_name"));
                    if (filteredLessonsArray.length > 0){
                        setLessons(filteredLessonsArray);
                        console.log(filteredLessonsArray);
                    }
                }
                return () => clearTimeout(delay);
            });
        }
    }, [])


    function createNewLesson(){
        let newLessonId = initializeNewLessonPlan(uid);
        navigate(`/l/${newLessonId}`)
    }

    return(
        <div className='bg-[#F6F6F6] max-h-full min-h-screen'>
            <NavBar />
            <div class="p-4 md:ml-64 pt-14 pl-10 pr-10">
                <div class="">
                    <h1 className='font-sans font-semibold text-4xl mb-1'>Your lesson plans</h1>
                    <h1 className='font-light font-sans text-xl mb-4'>Build end-to-end lesson plans for your classes</h1>
                    <div className='flex flex-row justify-left'>
                        <button
                            onClick={() => createNewLesson()}
                            className='bg-[#353535] hover:bg-[#555555] duration-200 ease-in-out text-white rounded-md text-sm px-3 py-2'>Create a new lesson</button>
                    </div>
                </div>
                <div class="mt-8">
                {isLoading ? (
                    // Display the loading component while waiting for lessons
                    <LoadingCardsComponents />
                ) : lessons && lessons.length > 0 ? (
                    // Display lessons once they are loaded
                    <div>
                    {lessons.map((lesson) => (
                        <LessonCard
                        date={lesson.date}
                        description={lesson.description}
                        title={lesson.title}
                        preLessonSet={lesson.preLessonSet}
                        learningObjectiveSet={lesson.learningObjectiveSet}
                        assessmentSet={lesson.assessmentSet}
                        lessonFlowSet={lesson.lessonFlowSet}
                        lid={lesson.lid}
                        />
                    ))}
                    </div>
                ) : (
                    // No existing lessons
                    <div></div>
                )}
            </div>
            </div>
        </div>
    )
}

export default LessonLanding;