import React, { useState, useRef, useEffect } from 'react';
import { SSE } from "sse.js";
import posthog from 'posthog-js'


function DegreeCard({classDescription, academicStandard, audienceValue, behaviorValue, conditionValue, setDegreeValue}){
    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [degreeAnswer, setDegreeAnswer] = useState('');
    const [degreeFeedback, setDegreeFeedback] = useState('');
    const [degreeMessageHistory, setDegreeMessageHistory] = useState([])
    const [disableButtons, setDisableButtons] = useState(false);

    // References
    const degreeResultRef = useRef();
    useEffect(() => {
        degreeResultRef.current = degreeFeedback;
    }, [degreeFeedback]);

    /// 7-3-23 PROMPT INFO
    // let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.
    // \n\nBelow are three examples of learning objectives for the cognitive, psychomotor, and affective domains:\n\nCognitive: Given a poem, the student will be able to identify two instances where the poem author used figurative language.\n\nPsychomotor: During a scrimmage, the student will be able to complete an offensive screen that frees up the student’s teammate for a shot.\n\nAffective: By the end of the semester, the learner will be able to act on a belief they hold about a social cause they deem important to bring about change to their community.`
    
    // 7-25-23 PROMPT INFO
    // let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish. For example, you may want your students to be able to identify the main character in a story they have read. So, if you have them read a story, they would be able to identify who the main character is. How might they demonstrate that they can identify the main character? They could do this in many ways. They could verbally tell you if you asked them. They could write a short paragraph that indicates who the main character is. They could pick the main character out of a list of characters on an objective-type quiz that you have given students. These examples of how your students could demonstrate that they can identify the main character are call assessments. These assessments allow you to evaluate whether your students are able to meet the learning objective. An assessment must align directly with a learning objective."
    // let frameworkExamples = "Let’s look at two examples of learning objectives and then determine the different ABCD elements that make up the different objectives.\n\nLearning Objective #1: At the conclusion of the unit on basketball, students will be able to shoot a free throw with proper form.\nWith this objective, the audience is students. Their behavior is to shoot a free throw. The condition is at the end of the unit on basketball. The degree is with proper form. Students would demonstrate this behavior by actually shooting a free throw for the teacher to see. The shooting of the free throw would be the assessment. The measurement would be the actual free throw and if they made it and how well they did in using proper form. The evaluation would be to determine how well they shot the free throw and displayed the necessary elements of proper form.\n\nLearning Objective #2: After students have read the Great Gatsby, the students will be able to evaluate Fitzgerald’s use of metaphor.\nThe audience is the students. The behavior is to evaluate how Fitzgerald’s use of metaphor helped describe the complexities of the 1920s in the United States. The condition is after reading the Great Gatsby. The degree would be to how clearly the student evaluated how Fitzgerald’s use of metaphor helped describe the complexities of the 1920s in the United States."
    // frameworkIntroduction = frameworkIntroduction + frameworkExamples
    // let degreeGuidelines = "Degree, which is the D in ABCD, is the level of proficiency or competence that the learner is expected to achieve after learning the material. When writing Degree, an educator should consider the following: How will the behavior need to be performed or demonstrated? The degree often describes what is acceptable performance. The degree, in sum, describes what is required for the learner to be considered proficient at the behavior. The specificity of the degree will vary. Often, the degree will not be included if it is easy to measure or if it is obvious (e.g., pass/fail, 100% accuracy is needed)."
    
    /// PROMPT INFO
    let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish.";
    let degreeGuidelines = "Degree, which is the D in ABCD, is the level of proficiency or competence that the learner is expected to achieve after learning the material. When writing Degree, an educator should consider the following: How will the behavior need to be performed or demonstrated? The degree often describes what is acceptable performance. The degree, in sum, describes what is required for the learner to be considered proficient at the behavior. The specificity of the degree will vary. Often, the degree will not be included if it is easy to measure or if it is obvious (e.g., pass/fail, 100% accuracy is needed)."
    let commonDegreeFailures = "When writing the Degree component, common traps to avoid include: not making the default degree specific to the behavior. Because degree is measuring behavior, the two parts should be consistent and complementary."
    frameworkIntroduction = `${frameworkIntroduction}\n\n ${degreeGuidelines}\n\n${commonDegreeFailures}\n\n`
    /// END OF PROMPT INFO

    /// OPENAI INFO
    const API_KEY = "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";
    /// END OF OPENAI INFO

    function updateDegreeAnswer(e){
        const newValue = e.target.value;
        setDegreeAnswer(newValue);
    }

    function refreshDegreeAnswer(){
        setDegreeAnswer('');
    }

    function generateDegreeFeedback(){

        if (classDescription === "") {classDescription = "K-12";}
        if (academicStandard === "") {academicStandard = "for a lesson";}
        else {academicStandard = `on the following standard: ${academicStandard}`}
        if (degreeAnswer === ""){return;}
        setDisableButtons(true);

        if (degreeMessageHistory.length === 0){
            // #1: Reset feedback, create new prompt
            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Condition and you, an instructional coach, will either either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audienceValue}. My behavior is: ${behaviorValue}. My condition is: ${conditionValue}. For Degree, I want to use: "${degreeAnswer}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${degreeGuidelines}\n\n${conversationContext}`
            console.log(fullPrompt)
            setDegreeFeedback('');

            posthog.capture('Degree Input', { property: degreeAnswer })
            setDegreeMessageHistory(prevMessages => [...prevMessages, 
                {"role": "user", "content": fullPrompt}
            ]);

            // #2: create request + stream response
            let data = { 
                model: "gpt-4-0613", 
                messages: [
                    {"role": "user", "content": fullPrompt}
                ],
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let source = new SSE("https://api.openai.com/v1/chat/completions", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${API_KEY}`
                },
                method: "POST",
                payload: JSON.stringify(data) 
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content; // 7-25 new field from chat endpoint
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    // THINGS TO DO WHEN STREAMING HAS ENDED
                    source.close();
                    setDegreeValue(degreeAnswer);
                    setDisableButtons(false);
                    posthog.capture('Degree Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        degreeResultRef.current = degreeResultRef.current + char;
                        setDegreeFeedback(degreeResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()

        } else {

            // #1: Reset feedback, update message history with the new prompt
            setDegreeMessageHistory(prevMessages => [...prevMessages,
                {"role": "assistant", "content": degreeFeedback}
            ]);
            let fullPrompt = "Educator: My new degree is: " + degreeAnswer + "\n\nInstructional Coach:"
            setDegreeMessageHistory(prevMessages => [...prevMessages,
                {"role": "user", "content": fullPrompt}
            ]);
            setDegreeFeedback("");
            posthog.capture('Degree Input', { property: degreeAnswer })

            // #1.5 Because state doesn't immediately updated, create copy of state to feed into API call
            let copiedDegreeMessageHistory = [...degreeMessageHistory];
            copiedDegreeMessageHistory.push({"role": "assistant", "content": degreeFeedback})
            copiedDegreeMessageHistory.push({"role": "user", "content": fullPrompt})

            // #2+3: create request and stream response
            console.log(copiedDegreeMessageHistory)
            let data = {
                model: "gpt-4-0613",
                messages: copiedDegreeMessageHistory,
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let chatEndpoint = "https://api.openai.com/v1/chat/completions"
            let source = new SSE(chatEndpoint, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`
            },
                method: "POST",
                payload: JSON.stringify(data)
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";


            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content; // 7-25 new field from chat endpoint
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    source.close();
                    setDegreeValue(degreeAnswer);
                    setDisableButtons(false);
                    posthog.capture('Degree Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        degreeResultRef.current = degreeResultRef.current + char;
                        setDegreeFeedback(degreeResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()
        }
    }

    return (
        <div className='w-full'>
            <div className='ml-14 mr-14 text-left'>
                <h1 className='text-md font-normal text-[#272727] mb-1'>Degree*</h1>
                <textarea value={degreeAnswer} onChange={updateDegreeAnswer} className='font-light text-sm max-h-24 w-3/5 px-2 py-2 border border-solid border-gray rounded-md' placeholder='With 80% accuracy'></textarea>
                <br></br>
                <button disabled={disableButtons} className="bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md" onClick={generateDegreeFeedback}>
                    <span class="material-symbols-outlined">check</span>
                </button>
                <button disabled={disableButtons} onClick={refreshDegreeAnswer} className="ml-2 bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">
                    <span class="material-symbols-outlined">refresh</span>
                </button>
                
            </div>
            <div className='flex flex-row justify-end ml-1 mt-8'>
                <h1 className='w-2/3 font-light ml-8 mt-8 mr-8'>{degreeFeedback}</h1>
                <img className="w-auto h-28 rounded-sm shadow-md mt-5 mb-5 mr-14" src={process.env.PUBLIC_URL + '/tim.jpg'} alt="Prof. Tim Green" ></img>
            </div>
        </div>
    )
}

export default DegreeCard;