import React, { useState, useEffect } from "react";
import BuilderNavBar from "./BuilderNavBar";
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';
import posthog from 'posthog-js'
import "./LearningObjective.css"

// Pages
import ClassroomInformation from "./InputCards/ClassroomInformation";
import ReviewerIntro from "./InputCards/ReviewerIntro";
import AudienceCard from './InputCards/AudienceCard';
import BehaviorCard from './InputCards/BehaviorCard';
import ConditionCard from './InputCards/ConditionCard';
import DegreeCard from './InputCards/DegreeCard';
import FinalObjective from './InputCards/FinalObjective';
import { initializeNewLesson } from "../../Components/Authentication/Firebase";

function LearningObjective(){

    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [classroomDescription, setClassroomDescription] = useState('');
    const [lessonTitle, setLessonTitle] = useState('')
    const [academicStandard, setAcademicStandard] = useState('');
    const [objectiveAudience, setObjectiveAudience] = useState('');
    const [objectiveBehavior, setObjectiveBehavior] = useState('');
    const [objectiveCondition, setObjectiveCondition] = useState('');
    const [objectiveDegree, setObjectiveDegree] = useState('');
    const [learningObjective, setLearningObjective] = useState('');
    const [currentInputCard, setCurrentInputCard] = useState(0);
    const [uid, setUid] = useState('');

    const [currentBodyText, setCurrentBodyText] = useState('')

    // Write down all ABCDs generated, submit as history to Firebase
    const [listOfABCDs, setListOfABCDs] = useState([]);
    function updateListOfABCDs(newListItem){
        const updatedList = [...listOfABCDs, newListItem];
        setListOfABCDs(updatedList);
    }

    // Check if user is signed in\
    const navigate = useNavigate('');
    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigate('/')
        } else {
            setUid(signedIn.uid);
        }
    })
    

    function handleAcademicStandard(response){
        setAcademicStandard(response);
    }

    function handleLessonTitle(response){
        setLessonTitle(response);
        console.log(`${lessonTitle}`)
    }

    function handleClassroomDescription(response){
        setClassroomDescription(response);
    }

    function handleAudienceValue(response){
        setObjectiveAudience(response);
        updateListOfABCDs(response);
    }

    function handleBehaviorValue(response){
        setObjectiveBehavior(response);
        updateListOfABCDs(response);
    }

    function handleConditionValue(response){
        setObjectiveCondition(response);
        updateListOfABCDs(response);
    }

    function handleDegreeValue(response){
        setObjectiveDegree(response);
        updateListOfABCDs(response);
    }

    function handleLearningObjectiveValue(response){
        setLearningObjective(response);
        console.log(`Setting learning objective: ${response}`);
        if (lessonTitle === ""){
            initializeNewLesson(uid, 'My lesson title', response, listOfABCDs);
        } else {
            initializeNewLesson(uid, lessonTitle, response, listOfABCDs);
        }
    }

    function mountInputCards(){
        if (currentInputCard === 0){
            return <ClassroomInformation setAcademicStandardValue={handleAcademicStandard} setClassroomDescriptionValue={handleClassroomDescription} />
        } else if (currentInputCard === 1) {
            return <ReviewerIntro />
        } else if (currentInputCard === 2) {
            return <AudienceCard 
                classDescription={classroomDescription} 
                academicStandard={academicStandard} 
                setAudienceValue={handleAudienceValue}/>
        } else if (currentInputCard === 3) {
            return <BehaviorCard
                classDescription={classroomDescription}
                academicStandard={academicStandard}
                audienceValue={objectiveAudience}
                setBehaviorValue={handleBehaviorValue}
            />
        } else if (currentInputCard === 4) {
            return <ConditionCard 
                classDescription={classroomDescription}
                academicStandard={academicStandard}
                audienceValue={objectiveAudience}
                behaviorValue={objectiveBehavior}
                setConditionValue={handleConditionValue}
            />
        } else if (currentInputCard === 5) {
            return <DegreeCard 
                classDescription={classroomDescription}
                academicStandard={academicStandard}
                audienceValue={objectiveAudience}
                behaviorValue={objectiveBehavior}
                conditionValue={objectiveCondition}
                setDegreeValue={handleDegreeValue}
            />
        } else if (currentInputCard === 6) {
            return <FinalObjective 
                classDescription={classroomDescription}
                academicStandard={academicStandard}
                audienceValue={objectiveAudience}
                behaviorValue={objectiveBehavior}
                conditionValue={objectiveCondition}
                degreeValue={objectiveDegree}
                setFinalLearningObjective={handleLearningObjectiveValue}
            />
        }
         else {
            return <ClassroomInformation />
        }
    }

    function navigateBackwards(){
        if (currentInputCard === 0){
            navigate('/home');
        } else if (currentInputCard === 1) {
            setCurrentInputCard(0);
        } else if (currentInputCard === 2) {
            setCurrentInputCard(1); 
        } else if (currentInputCard === 3) {
            setCurrentInputCard(2);
        } else if (currentInputCard === 4) {
            setCurrentInputCard(3);
        } else if (currentInputCard === 5) {
            setCurrentInputCard(4);
        } else if (currentInputCard === 6) {
            setCurrentInputCard(5);
        } else {
            setCurrentInputCard(0);
        }
    }   

    function navigateForwards(){
        if (currentInputCard === 0){
            setCurrentInputCard(1);
            posthog.capture('Academic Standard', { property: academicStandard })
            posthog.capture('Classroom Description', { property: classroomDescription })
        } else if (currentInputCard === 1) {
            setCurrentInputCard(2);
        } else if (currentInputCard === 2) {
            setCurrentInputCard(3);
        } else if (currentInputCard === 3) {
            setCurrentInputCard(4);
        } else if (currentInputCard === 4) {
            setCurrentInputCard(5);
        } else if (currentInputCard === 5) {
            setCurrentInputCard(6);
        } else if (currentInputCard === 6) {
            setCurrentInputCard(0);
        }
        else {
            setCurrentInputCard(0);
        }
    }

    return(
        <div className="">
            <BuilderNavBar sendLessonTitle={handleLessonTitle} />
            <div className='bg-[#FAF7F3] h-screen'>
                {/* Instruction section */}
                <div className="pb-8 pt-8 border-solid border-gray drop-shadow-sm">
                    <h1 className="ml-14 mr-14 font-bold text-2xl mt-2">Step #1: Create a Learning Objective</h1>
                        {currentInputCard === 0 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">
                        To build your lesson plan, we will start by creating your learning objective. In this step, we will use the {' '}
                        <a
                            target="_blank"
                            className="text-[#073F74] hover:text-[#102336]"
                            href="https://lib.guides.umd.edu/c.php?g=598357&p=4144007"
                            rel="noreferrer"
                        >
                            ABCD method
                        </a>{' '}
                        and{' '}
                        <a
                            target="_blank"
                            className="text-[#073F74] hover:text-[#102336]"
                            href="https://cft.vanderbilt.edu/guides-sub-pages/blooms-taxonomy/#:~:text=Familiarly%20known%20as%20Bloom's%20Taxonomy,Analysis%2C%20Synthesis%2C%20and%20Evaluation."
                            rel="noreferrer"
                        >
                            Bloom’s Taxonomy
                        </a>{' '}
                        to iteratively develop a learning objective that matches your classroom criteria. Below, please provide information about your classroom and the academic or learning standard you will address in this lesson.
                        </h1>
                    ) : 
                        currentInputCard === 1 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">In this step, you will practice writing out each part of the {' '}
                        <a
                            target="_blank"
                            className="text-[#073F74] hover:text-[#102336]"
                            href="https://lib.guides.umd.edu/c.php?g=598357&p=4144007"
                            rel="noreferrer"
                        >
                            ABCD method
                        </a>{' '}
                        (Audience, Behavior, Condition, and Degree) for your learning objective. For each part, you will draft an answer, submit and receive immediate feedback from the instructor, and make adjustments until you are satisfied with your answer. At the end, we will put all the parts together to produce a final learning objective.</h1>
                    ) : 
                        currentInputCard === 2 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6"><b className='italic font-semibold'>Audience.</b> Audience describes the intended audience for your lesson. Consider: Who will be expected to meet the learning objective? An example of the Audience in a learning objective is: 'My ENGL397 students will be able to...'</h1>
                    ) :
                    currentInputCard === 3 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6"><b className='italic font-semibold'>Behavior.</b> Behavior refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material. Consider: What is the outcome of the learners participating in the instruction?  In other words, what is the audience expected to know or be able to do based on engaging in the instruction? This needs to be written as a verb (this is the “behavior”).</h1>
                    ) :
                    currentInputCard === 4 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6"><b className='italic font-semibold'>Condition.</b> Condition describes the specific circumstances or resources that learners will use to demonstrate their knowledge or skill. Consider: what are the constraints that will be placed on learners to demonstrate their ability to see the outcome? For example: at the end of the lesson/unit/course. It could also be something such as given 30 minutes or using a communication tool of their choosing.</h1>
                    ) :
                    currentInputCard === 5 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6"><b className='italic font-semibold'>Degree.</b> Degree is the level of proficiency or competence that the learner is expected to achieve after learning the material. Consider: how will the behavior need to be performed or demonstrated? The degree often describes what is acceptable performance. The degree, in sum, describes what is required for the learner to be considered proficient at the behavior. </h1>
                    ) :
                    currentInputCard === 6 ? (
                        <h1 className="ml-14 mr-14 font-light text-lg mt-2 leading-6">Nice job completing each component! Generate your final learning objective below.</h1>
                    ) : null
                    }
                </div>

                {/* Fill-out section */}
                {mountInputCards()}

                {/* Navigate forward and backward */}
                <div className="ml-14 mt-6 flex flex-row justify-start">
                    <button className="border border-gray border-solid rounded-md" onClick={navigateBackwards}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">west</span>
                    </button>
                    <button className="border border-gray border-solid ml-3 rounded-md" onClick={navigateForwards}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">east</span>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default LearningObjective;