import React, { useState, useRef, useEffect } from 'react';
import { SSE } from "sse.js";
import posthog from 'posthog-js'

function ConditionCard({classDescription, academicStandard, audienceValue, behaviorValue, setConditionValue}){
    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [conditionAnswer, setConditionAnswer] = useState('');
    const [conditionFeedback, setConditionFeedback] = useState('');
    const [conditionMessageHistory, setConditionMessageHistory] = useState([])  // 7-25: changing message history from string to array of messages
    const [disableButtons, setDisableButtons] = useState(false);

    // References
    const conditionResultRef = useRef();
    useEffect(() => {
        conditionResultRef.current = conditionFeedback;
    }, [conditionFeedback]);

    /// 7-3-23 PROMPT INFO
    // let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.
    // \n\nBelow are three examples of learning objectives for the cognitive, psychomotor, and affective domains:\n\nCognitive: Given a poem, the student will be able to identify two instances where the poem author used figurative language.\n\nPsychomotor: During a scrimmage, the student will be able to complete an offensive screen that frees up the student’s teammate for a shot.\n\nAffective: By the end of the semester, the learner will be able to act on a belief they hold about a social cause they deem important to bring about change to their community.`
    
    /// 7-25-23 PROMPT INFO
    // let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish. For example, you may want your students to be able to identify the main character in a story they have read. So, if you have them read a story, they would be able to identify who the main character is. How might they demonstrate that they can identify the main character? They could do this in many ways. They could verbally tell you if you asked them. They could write a short paragraph that indicates who the main character is. They could pick the main character out of a list of characters on an objective-type quiz that you have given students. These examples of how your students could demonstrate that they can identify the main character are call assessments. These assessments allow you to evaluate whether your students are able to meet the learning objective. An assessment must align directly with a learning objective."
    // let conditionGuidelines = "Condition, which is the C in ABCD, describes the specific circumstances or resources that learners will use to demonstrate their knowledge or skill. When writing Condition, an educator should consider the following: What are the constraints that will be placed on learners to demonstrate their ability to see the outcome (I.e., the behavior)? The constructions are the specific conditions you place on your learners. It could be, for example, at the end of the lesson/unit/course. It could also be something such as given 30 minutes or using a communication tool of their choosing. The constraint (or constraints) included in the learning objective needs to provide specific guidance/direction to learners and educators on how the behavior is expected to be demonstrated."

    // PROMPT INFO
    let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish.";
    let conditionGuidelines = "Condition, which is the C in ABCD, describes the specific circumstances or resources that learners will use to demonstrate their knowledge or skill. When writing Condition, an educator should consider the following: What are the constraints that will be placed on learners to demonstrate their ability to see the outcome (I.e., the behavior)? The constructions are the specific conditions you place on your learners. It could be, for example, at the end of the lesson/unit/course. It could also be something such as given 30 minutes or using a communication tool of their choosing. The constraint (or constraints) included in the learning objective needs to provide specific guidance/direction to learners and educators on how the behavior is expected to be demonstrated."
    let commonConditionFailures = "When writing the Condition component, common traps to avoid include: being non-specific or non-discrete about how the target audience will demonstrate their knowledge/skill. To avoid this, ensure you can answer the question 'how will I evaluate the behavior my audience engages in?'";
    frameworkIntroduction = `${frameworkIntroduction}\n\n ${conditionGuidelines}\n\n${commonConditionFailures}\n\n`
    /// END OF PROMPT INFO

    /// OPENAI INFO
    const API_KEY = "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";
    /// END OF OPENAI INFO

    function updateConditionAnswer(e){
        const newValue = e.target.value;
        setConditionAnswer(newValue);
    }

    function refreshConditionAnswer(){
        setConditionAnswer('');
    }

    function generateConditionFeedback(){

        if (classDescription === "") {classDescription = "K-12";}
        if (academicStandard === "") {academicStandard = "for a lesson";}
        else {academicStandard = `on the following standard: ${academicStandard}`}
        if (conditionAnswer === ""){return;}
        setDisableButtons(true);

        if (conditionMessageHistory.length === 0){  // 7-25 checking for length of message history array

            // #1: Reset feedback, create new prompt
            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Condition and you, an instructional coach, will either either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audienceValue}. My behavior is: ${behaviorValue}. For Condition, I want to use: "${conditionAnswer}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${conditionGuidelines}\n\n${conversationContext}`
            console.log(fullPrompt)
            setConditionFeedback('');

            posthog.capture('Condition Input', { property: conditionAnswer })
            setConditionMessageHistory(prevMessages => [...prevMessages, 
                {"role": "user", "content": fullPrompt}
            ]);

            // #2: create request + stream response
            let data = { 
                model: "gpt-4-0613", 
                messages: [
                    {"role": "user", "content": fullPrompt}
                ],
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let source = new SSE("https://api.openai.com/v1/chat/completions", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${API_KEY}`
                },
                method: "POST",
                payload: JSON.stringify(data) 
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data); 
                let text = payload.choices[0].delta.content; // 7-25 new field from chat endpoint
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    // THINGS TO DO WHEN STREAMING HAS ENDED
                    source.close();
                    setConditionValue(conditionAnswer);
                    setDisableButtons(false);
                    posthog.capture('Condition Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        conditionResultRef.current = conditionResultRef.current + char;
                        setConditionFeedback(conditionResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()

        } else {

            // #1: Reset feedback, update message history with the new prompt
            setConditionMessageHistory(prevMessages => [...prevMessages,
                {"role": "assistant", "content": conditionFeedback}
            ]);
            let fullPrompt = "Educator: My new condition is: " + conditionAnswer + "\n\nInstructional Coach:"
            setConditionMessageHistory(prevMessages => [...prevMessages,
                {"role": "user", "content": fullPrompt}
            ]);
            setConditionFeedback("");
            posthog.capture('Condition Input', { property: conditionAnswer })

            // #1.5 Because state doesn't immediately updated, create copy of state to feed into API call
            let copiedConditionMessageHistory = [...conditionMessageHistory];
            copiedConditionMessageHistory.push({"role": "assistant", "content": conditionFeedback})
            copiedConditionMessageHistory.push({"role": "user", "content": fullPrompt})

            // #2+3: create request and stream response
            console.log(copiedConditionMessageHistory)
            let data = {
                model: "gpt-4-0613",
                messages: copiedConditionMessageHistory,
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let chatEndpoint = "https://api.openai.com/v1/chat/completions"
            let source = new SSE(chatEndpoint, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`
            },
                method: "POST",
                payload: JSON.stringify(data)
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";


            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content; // 7-25
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    source.close();
                    setConditionValue(conditionAnswer);
                    setDisableButtons(false);
                    posthog.capture('Condition Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        conditionResultRef.current = conditionResultRef.current + char;
                        setConditionFeedback(conditionResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()
        }
    }

    return (
        <div className='w-full'>
            <div className='ml-14 mr-14 text-left'>
                <h1 className='text-md font-normal text-[#272727] mb-1'>Condition*</h1>
                <textarea value={conditionAnswer} onChange={updateConditionAnswer} className='font-light text-sm max-h-24 w-3/5 px-2 py-2 border border-solid border-gray rounded-md' placeholder='At the conclusion of textbook section 6.2'></textarea>
                <br></br>
                <button disabled={disableButtons} className="bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md" onClick={generateConditionFeedback}>
                    <span class="material-symbols-outlined">check</span>
                </button>
                <button disabled={disableButtons} onClick={refreshConditionAnswer} className="ml-2 bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">
                    <span class="material-symbols-outlined">refresh</span>
                </button>
                
            </div>
            <div className='flex flex-row justify-end ml-1 mt-8'>
                <h1 className='w-2/3 font-light ml-8 mt-8 mr-8'>{conditionFeedback}</h1>
                <img className="w-auto h-28 rounded-sm shadow-md mt-5 mb-5 mr-14" src={process.env.PUBLIC_URL + '/tim.jpg'} alt="Prof. Tim Green" ></img>
            </div>
        </div>
    )
}

export default ConditionCard;