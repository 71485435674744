import React from "react";

function LoadingCardsComponents(){
    return(
        <div>
            <div role="status" class="space-y-2.5 animate-pulse max-w-lg mt-10">
                    <div class="flex items-center w-full space-x-2">
                        <div class="h-2.5 bg-gray-200 rounded-full  w-32"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-full"></div>
                    </div>
                    <div class="flex items-center w-full space-x-2 max-w-[480px]">
                        <div class="h-2.5 bg-gray-200 rounded-full w-full"></div>
                                <div class="h-2.5 bg-gray-300 rounded-full w-full"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
                    </div>
                    <div class="flex items-center w-full space-x-2 max-w-[400px]">
                        <div class="h-2.5 bg-gray-300 rounded-full w-full"></div>
                        <div class="h-2.5 bg-gray-200 rounded-full w-80"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-full"></div>
                    </div>
                    <div class="flex items-center w-full space-x-2 max-w-[480px]">
                        <div class="h-2.5 bg-gray-200 rounded-full w-full"></div>
                                <div class="h-2.5 bg-gray-300 rounded-full w-full"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
                    </div>
                    <div class="flex items-center w-full space-x-2 max-w-[440px]">
                        <div class="h-2.5 bg-gray-300 rounded-full w-32"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full w-24"></div>
                        <div class="h-2.5 bg-gray-200 rounded-full w-full"></div>
                    </div>
                    <div class="flex items-center w-full space-x-2 max-w-[360px]">
                        <div class="h-2.5 bg-gray-300 rounded-fullw-full"></div>
                        <div class="h-2.5 bg-gray-200 rounded-fullw-80"></div>
                        <div class="h-2.5 bg-gray-300 rounded-full  w-full"></div>
                    </div>
                    <span class="sr-only">Loading...</span>
                </div>
        </div>
    )
}

export default LoadingCardsComponents;