import React, { useState } from 'react';

function ClassroomInformation({setAcademicStandardValue, setClassroomDescriptionValue}){

    const [classroomDescription, setClassroomDescription] = useState('');
    const [academicStandard, setAcademicStandard] = useState('');
    // const [errorMessage, setErrorMessage] = useState('') TODO: do error messaging for incomplete inputs

    function updateClassroomDescription(e){
        const newValue = e.target.value;
        setClassroomDescription(newValue);
        setClassroomDescriptionValue(newValue) // callback
    }

    function updateAcademicStandard(e){
        const newValue = e.target.value;
        setAcademicStandard(newValue);
        setAcademicStandardValue(newValue); // callback
    }

    return(
        <div className="ml-14 mr-14">
            {/* <h1 className="text-xl font-semibold text-[#272727]">First, describe your classroom in one sentence.</h1> */}
            <h1 className="text-md font-normal text-[#272727]">Classroom description*</h1>
            <div className="flex flex-row justify-start mt-1 mb-6">
                <input type="text" value={classroomDescription} onChange={updateClassroomDescription} className="font-light px-2 py-2 text-sm rounded-md w-3/5 border border-solid border-gray" placeholder="8th grade biology class"></input>
            </div>
            {/* <h1 className="text-xl font-semibold text-[#272727]">Next, copy and paste the academic standard you will address.</h1> */}
            <h1 className="text-md font-normal text-[#272727]">Academic standard</h1>
            <div className="flex flex-row justify-start mt-1">
                <input type="text" value={academicStandard} onChange={updateAcademicStandard} className="font-light mb-10 px-2 py-2 text-sm rounded-md w-3/5 border border-solid border-gray" placeholder="(MS-PS1-1). Develop models to describe the atomic composition of simple molecules and extended..."></input>
            </div>
            {/* <h1>{errorMessage}</h1> */}
        </div>
    )
}

export default ClassroomInformation;