function ABreadcrumb({step}){
    return(
        <div className="">

        <div className="p-4">
            <ol class="items-center justify-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">

                {/* Step #1: Intro */}
                <li
                    className={`flex items-center ${
                    step === 1 ? 'text-blue-600' : 'text-gray-500'
                    } space-x-2.5`}
                >
                    <span
                    className={`flex items-center justify-center w-8 h-8 border ${
                        step === 1
                        ? 'border-blue-600 rounded-full shrink-0'
                        : 'border-gray-500 rounded-full shrink-0'
                    }`}
                    >
                        1
                    </span>
                    <span>
                        <h3 class="font-medium leading-tight">Intro</h3>
                       
                    </span>
                </li>

                {/* Step #2: Pre-Assessment */}
                <li className={`flex items-center ${
                    step === 2 ? 'text-blue-600' : 'text-gray-500'
                    } space-x-2.5`}>
                    <span className={`flex items-center justify-center w-8 h-8 border ${
                        step === 2
                        ? 'border-blue-600 rounded-full shrink-0'
                        : 'border-gray-500 rounded-full shrink-0'
                    }`}>
                        2
                    </span>
                    <span>
                        <h3 class="font-medium leading-tight">Pre-Assessment</h3>
                        {/* <p class="text-sm">Step details here</p> */}
                    </span>
                </li>

                {/* Step #3: Formative Assessment */}
                <li className={`flex items-center ${
                    step === 3 ? 'text-blue-600' : 'text-gray-500'
                    } space-x-2.5`}>
                    <span className={`flex items-center justify-center w-8 h-8 border ${
                        step === 3
                        ? 'border-blue-600 rounded-full shrink-0'
                        : 'border-gray-500 rounded-full shrink-0'
                    }`}>
                        3
                    </span>
                    <span>
                        <h3 class="font-medium leading-tight">Formative</h3>
                    </span>
                </li>

                {/* Step #4: Summative Assessment */}
                <li className={`flex items-center ${
                    step === 4 ? 'text-blue-600' : 'text-gray-500'
                    } space-x-2.5`}>
                    <span className={`flex items-center justify-center w-8 h-8 border ${
                        step === 4
                        ? 'border-blue-600 rounded-full shrink-0'
                        : 'border-gray-500 rounded-full shrink-0'
                    }`}>
                        4
                    </span>
                    <span>
                        <h3 class="font-medium leading-tight">Summative</h3>
                    </span>
                </li>

                {/* Step #5: Summary */}
                <li className={`flex items-center ${
                    step === 5 ? 'text-blue-600' : 'text-gray-500'
                    } space-x-2.5`}>
                    <span className={`flex items-center justify-center w-8 h-8 border ${
                        step === 5
                        ? 'border-blue-600 rounded-full shrink-0'
                        : 'border-gray-500 rounded-full shrink-0'
                    }`}>
                        5
                    </span>
                    <span>
                        <h3 class="font-medium leading-tight">Summary</h3>
                    </span>
                </li>

            </ol>
        </div>
    </div>
    )
}

export default ABreadcrumb;