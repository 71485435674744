// Library imports
import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { checkAuthStatus } from "../../Components/Authentication/AuthDetails";
import { checkIfLessonExists } from "../../Components/Authentication/Firebase";
import { useNavigate } from "react-router-dom";

// Components
import NavBar from '../Home/NavBar';
import EditBreadcrumb from './EditBreadcrumb';

// Step coordinator components
import LOCoordinator from './LearningObjective/LOCoordinator';
import PLCoordinator from './PreLesson/PLCoordinator';
import ACoordinator from './Assessment/ACoordinator';
import LFCoordinator from './LessonFlow/LFCoordinator';

function EditPage(){

    const { lessonId } = useParams();
    const [uid, setUid] = useState('');
    const [editType, setEditType] = useState('learning objective')
    const location = useLocation();
    const [exists, setExists] = useState(false);

    const [lessonDate, setLessonDate] = useState('');
    const [lesonDescription, setLessonDescription] = useState('');
    const [lessonTitle, setLessonTitle] = useState('');

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    function seeIfLessonExists(jsonData){
        if (jsonData === 'null'){
            setExists(false);
        } else {
            setExists(true)
            let parsedJsonData = JSON.parse(jsonData);
            
            // Lesson plan information
            setLessonDate(parsedJsonData.date);
            setLessonDescription(parsedJsonData.description);
            setLessonTitle(parsedJsonData.title)

        }
    }

    function checkWhatFacetIsBeingEdited(){
        const queryParams = new URLSearchParams(location.search);
        
        const isLearningObjective = queryParams.get('learningobjective');
        if (isLearningObjective != null) {
            setEditType('Learning Objective')
        }
        const isAssessment = queryParams.get('assessment');
        if (isAssessment !== null){
            setEditType('Assessment');
        }
        const isLessonOutline = queryParams.get('lessonflow');
        if (isLessonOutline !== null){
            setEditType('Lesson Flow');
        }
        const isInstructionContent = queryParams.get('prelesson');
        if (isInstructionContent !== null){
            setEditType('Pre-Lesson');
        }
    }

    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            
            // Step #1: Check if lesson xists
            checkIfLessonExists(signedIn.uid, lessonId, seeIfLessonExists);
            
            // Step #2: Check what facet is being edited
            checkWhatFacetIsBeingEdited();

            setUid(signedIn.uid)
        }
    }, [])

    if(exists){
    return(
        <div className="bg-[#F6F6F6] min-h-screen max-h-full">
            <NavBar />
            <div class="p-4 md:ml-64 pt-10 pl-10 pr-10">
                <EditBreadcrumb 
                    lessonId={lessonId}
                    lessonTitle={lessonTitle}
                    facetToEdit={editType}
                />
                
                {/* Edit section */}
                <div className='mt-10'>
                    {
                        editType === "Learning Objective" ? (
                            <LOCoordinator
                                lessonId={lessonId}
                                uid={uid}
                            />
                        ) : editType === "Assessment" ? (
                            <ACoordinator 
                                lessonId={lessonId}
                                uid={uid}
                            />
                        ) : editType === "Lesson Flow" ? (
                            <LFCoordinator
                                lessonId={lessonId}
                                uid={uid}
                            />
                        ) : editType === "Pre-Lesson" ? (
                            <PLCoordinator 
                                lessonId={lessonId}
                                uid={uid}
                            />
                        ) : (
                            <LOCoordinator
                                lessonId={lessonId}
                                uid={uid}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
    } else {
        return(
            <div>

                {/* For some reason, this is flashing at random times, which creates a poor page rendering experience. Need to debug. */}

                <NavBar />

                {/* <div className="mt-20 mr-20 ml-40">
                    <h1 className="font-serif text-4xl font-semibold">This lesson does not exist.</h1>
                    <h1 className="mt-1 font-sans text-lg font-light">Go back or just sit here. I don't care either way.</h1>
                </div> */}
            </div>
        )
    }
}

export default EditPage;