import React, { useState, useEffect } from "react";
import PLBreadcrumb from "./PLBreadcrumb";
import OpenAI from "openai";
import { useNavigate } from "react-router-dom";

// Slide helpers
import { preLessonSetLearnerNeeds, preLessonSlide1, preLessonSlide2, preLessonSlide4, preLessonSetIdentifyingStandard, preLessonSetFinished } from "../../../Components/Authentication/Firebase";

function PLCoordinator({lessonId, uid}){

    const [step, setStep] = useState(1);
    
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    function updateStep(direction){
        if (direction === 'forward'){
            if (step === 4){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step + 1)
            }
        } else if (direction === 'back'){
            if (step === 1){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step - 1);
            }   
        } else {
            return;
        }
    }

    let content;
    if (step === 1) {
        content = <PLStepOne stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 2) {
        content = <PLStepTwo stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 3) {
        content = <PLStepThree stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 4){
        content = <PLStepFour stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else {
        content = <PLStepOne stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    }

    return(
        <div className="relative">
            {content}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-white">
                <PLBreadcrumb step={step} />
            </div>
        </div>
    )
}

export default PLCoordinator;

export function PLStepOne({stepChangeCallback, userId, lessonId}){

    // nice to have: if academicStandard and classDescription are already set (user is redoing lesson), on render, set state to those values so they appear in inputs

    const [academicStandard, setAcademicStandard] = useState('');
    const [classDescription, setClassDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    function onAcademicStandardChange(event){setAcademicStandard(event.target.value)}
    function onClassDescriptionChange(event){setClassDescription(event.target.value)}

    function moveFromStep(direction){

        setErrorMessage('');

        if (direction === "back"){
            // In coordinator, should return to lesson plan page
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // Check if inputs are filled out
            if (academicStandard === '' || classDescription === ''){
                setErrorMessage('Please fill out both fields before continuing.')
                return;
            } else {
                // Save information to Firebase
                preLessonSlide1(userId, lessonId, classDescription, academicStandard)
                stepChangeCallback(direction);
            }

        } else {
            return;
        }
    }

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Pre-Lesson Preparation</h5>
            <p className="font-light leading-5 mt-1">In our pre-lesson preparation, we will <b>analyze your students' needs</b> and <b>identify the academic standard</b> you will be designing your lesson plan around.</p>
            
            <div class="mb-6 mt-6">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Class description</label>
                <input value={classDescription} onInputCapture={onClassDescriptionChange} placeholder="8th grade California Biology class teaching to NGSS standards" type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 font-light text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 font-normal">Important information: grade level, state, course title, etc.</p>
            </div>

            <div class="mb-6">
                <label for="default-input" class="block mb-2 text-sm font-medium text-gray-900">Academic standard</label>
                <input value={academicStandard} type="text" id="default-input" placeholder="MS-LS4-6.	Use mathematical representations to support explanations of how natural selection may lead to increases and decreases of specific traits in populations over time." onInputCapture={onAcademicStandardChange} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                <p id="helper-text-explanation" class="mt-2 text-sm text-gray-500 font-normal">Include sub-standards and accompanying info. Don't worry about formatting!</p>
            </div>

            <div className="mb-6">
                <p className="text-red-950 font-normal">{errorMessage}</p>
            </div>

            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function PLStepTwo({stepChangeCallback, userId, lessonId}){

    const [isLoading, setIsLoading] = useState(true);
    const [currentResponse, setCurrentResponse] = useState('');
    const [context, setContext] = useState([])
    const [instruction, setInstruction] = useState('')
    const [userFeedback, setUserFeedback] = useState('');

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // #1: Save analyzed learner need to firebase before continuing
            preLessonSetLearnerNeeds(userId, lessonId, currentResponse);

            // #2: Move to next section
            stepChangeCallback(direction);

        } else {
            return;
        }
    }

    async function submitUserResponse(){
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        // Step #1: Housekeeping before rendering new response
        let feedbackForPrompt = userFeedback;
        setUserFeedback('');
        setIsLoading(true);

        // Step #2: Build next prompt
        let nextPrompt = feedbackForPrompt; // customize later on
        let nextMessage = {role: 'user', content: nextPrompt}

        // Step #3: Generate next response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [...context, nextMessage],
        });

        // Step #4: Add response to context
        let nextNextMessage = completion.choices[0].message
        const newContext = [...context, nextMessage, nextNextMessage];
        setContext(newContext);

        // Step #5: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

    }

    async function onRenderCallback(classroomDescription, academicStandard){
        
        // Step #1: Build first message
        let prompt = `You are an instructional designer for K-12 schools. You are collaborating with an educator with the following characteristics:\n Classroom description: ${classroomDescription}\nAcademic standard:${academicStandard}\n\nIn two to three sentences, describe the students' needs for the teacher to keep in mind when preparing this lesson. Directly address the teacher:`
        let firstMessage = {role: 'system', content: prompt};

        // Step #2: Generate first response
            // "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",
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [firstMessage],
        });

        // Step #3: Add response to context
        let secondMessage = completion.choices[0].message
        const newContext = [...context, firstMessage, secondMessage];
        setContext(newContext);

        // Step #4: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my understanding, you can move to the next section.')

    }

    useEffect(() => {
        preLessonSlide2(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Analyzing Learner Needs</h5>
            <p className="font-light leading-5 mt-1">Based on your classroom description and academic standard, here is what I think about learner needs:</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }


            {/* Navigation menu */}
            <div class="flex mt-12">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function PLStepThree({stepChangeCallback, lessonId, userId}){

    const [isLoading, setIsLoading] = useState(true);
    const [currentResponse, setCurrentResponse] = useState('');
    const [context, setContext] = useState([])
    const [instruction, setInstruction] = useState('')
    const [userFeedback, setUserFeedback] = useState('');

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    function moveFromStep(direction){

        if (direction === "back"){
            stepChangeCallback(direction); // will become a re-rendering issue if I don't have the "check if this was already done" in place

        } else if (direction === "forward") {

            // #1: Save analyzed learner need to firebase before continuing
            preLessonSetIdentifyingStandard(userId, lessonId, currentResponse);

            // #2: Move to next section
            stepChangeCallback(direction);

        } else {
            return;
        }
    }

    // Without special prompting, is general
    async function submitUserResponse(){
        if (userFeedback === ''){
            setInstruction('If you are satisfied with my response, you can proceed.');
            return;
        }

        // Step #1: Housekeeping before rendering new response
        let feedbackForPrompt = userFeedback;
        setUserFeedback('');
        setIsLoading(true);

        // Step #2: Build next prompt
        let nextPrompt = feedbackForPrompt; // customize later on
        let nextMessage = {role: 'user', content: nextPrompt}

        // Step #3: Generate next response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [...context, nextMessage],
        });

        // Step #4: Add response to context
        let nextNextMessage = completion.choices[0].message
        const newContext = [...context, nextMessage, nextNextMessage];
        setContext(newContext);

        // Step #5: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('How should I adjust this? Once you are satisfied, you can move to the next section.')

    }

    async function onRenderCallback(classroomDescription, academicStandard){
        
        // Step #1: Build first message
        let prompt = `You are an instructional designer for K-12 schools. You are collaborating with an educator with the following characteristics:\n Classroom description: ${classroomDescription}\nAcademic standard:${academicStandard}\n\nIn two to three sentences, identify characteristics of the academic standard for the teacher to keep in mind when preparing this lesson. Directly address the teacher:`
        let firstMessage = {role: 'system', content: prompt};

        // Step #2: Generate first response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [firstMessage],
        });

        // Step #3: Add response to context
        let secondMessage = completion.choices[0].message
        const newContext = [...context, firstMessage, secondMessage];
        setContext(newContext);

        // Step #4: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('How should I adjust this? Once you are satisfied with my understanding, you can move to the next section.')

    }

    useEffect(() => {
        preLessonSlide2(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Evaluating the Academic Standard</h5>
            <p className="font-light leading-5 mt-1">Based on your classroom description and academic standard, here is what I think about the academic standard:</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between h-full">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }


            {/* Navigation menu */}
            <div class="flex mt-12">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )

}

export function PLStepFour({stepChangeCallback, userId, lessonId}){

    const [learnerNeeds, setLearnerNeeds] = useState('');
    const [identifiedStandard, setIdentifiedStandard] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }

    function onRenderCallback(learnerNeeds, identifiedStandard){
        
        // #1: Assign values created in previous 2 steps 
        setLearnerNeeds(learnerNeeds);
        setIdentifiedStandard(identifiedStandard);

        // #2: Render content
        setIsLoading(false);

        // #3: Update 'pre-lesson set' to true in Firebase
        preLessonSetFinished(userId, lessonId);
    }

     useEffect(() => {
        preLessonSlide4(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Summary</h5>
            <p className="font-light leading-5 mt-1">Let's review what we covered in our pre-lesson preparation.</p>

            {
                isLoading === true ? (
                    <div>
                        <div class="flex items-center justify-center w-full h-full">
                                <div role="status" className="mt-10">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                    </div>
                ) : (
                    <div className="mt-6">
                        <h1 className="font-medium text-xl pb-1">Analyzed Learner Needs</h1>
                        <p className="pb-3 font-light text-md">{learnerNeeds}</p>
                        <h1 className="font-medium text-xl pb-1">Identified Academic Standard</h1>
                        <p className="pb-3 font-light text-md">{identifiedStandard}</p>
                    </div>
                )
            }
            
            {/* Navigation menu */}
            <div class="flex mt-12 mb-36">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}