import React, { useState } from 'react';

function AssessmentInformation({setPrevAssessmentValue, setSuccessfulAssessmentsValue, setResourcesForLessonValue}){

    // TODO: error handling if values are not set
    //<h1>{errorMessage}</h1>

    const [previousAssessmentTechniques, setPreviousAssessmentTechniques] = useState('');
    const [successfulAssessments, setSuccessfulAssessments] = useState('');
    const [resourcesForLesson, setResourcesForLesson] = useState('');
    
    function updatePreviousAsssessmentTechniques(e){
        const newValue = e.target.value;
        setPreviousAssessmentTechniques(newValue);
        setPrevAssessmentValue(newValue);
    }
    function updateSuccessfulAssessments(e){
        const newValue = e.target.value;
        setSuccessfulAssessments(newValue);
        setSuccessfulAssessmentsValue(newValue);
    }
    function updateResourcesForLesson(e){
        const newValue = e.target.value;
        setResourcesForLesson(newValue);
        setResourcesForLessonValue(newValue);
    }

    return(
        <div className="ml-14 mr-14">

            {/* Question #1 */}
            <h1 className="text-md font-normal text-[#272727]">What are some of your previous assessments?*</h1>
            <div className="flex flex-row justify-start mt-1 mb-6">
                <input type="text" value={previousAssessmentTechniques} onChange={updatePreviousAsssessmentTechniques} className="font-light px-2 py-2 text-sm rounded-md w-3/5 border border-solid border-gray" placeholder="Multiple choice quiz, group presentations, Kahoot"></input>
            </div>

            {/* Question #2 */}
            <h1 className="text-md font-normal text-[#272727]">What worked? What didn't?*</h1>

            <div className="flex flex-row justify-start mt-2">
                <input type="text" value={successfulAssessments} onChange={updateSuccessfulAssessments} className="font-light mb-10 px-2 py-2 text-sm rounded-md w-3/5 border border-solid border-gray" placeholder="At-home quiz, class-based quiz"></input>
            </div>

            {/* Question #3 */}
            <h1 className="text-md font-normal text-[#272727]">What resources do you have?*</h1>

            <div className="flex flex-row justify-start mt-2">
                <input type="text" value={resourcesForLesson} onChange={updateResourcesForLesson} className="font-light mb-10 px-2 py-2 text-sm rounded-md w-3/5 border border-solid border-gray" placeholder="45 minute period, computers, textbooks, $25 budget"></input>
            </div>

        </div>
    )
}
export default AssessmentInformation;