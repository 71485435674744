import React, { useEffect, useState } from "react";
import NavBar from '../Home/NavBar';
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';

function SearchPage(){

    // need to make this a fucking component
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    document.title = `Search • ClassroomGPT`

    const [uid, setUid] = useState('');
    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            setUid(signedIn.uid);
        }
    }, [])

    function searchLessons(){
        let query = searchQuery;
        setSearchQuery('');

        // Find relevant lessons
    }

    const [searchQuery, setSearchQuery] = useState('');
    function updateSearchQuery(event){
        setSearchQuery(event.target.value);
    }

    return(
        <div className='bg-[#F6F6F6] min-h-screen max-h-full'>
            <NavBar />
            <div class="p-4 md:ml-64 pt-14 pl-10 pr-10">
                <h1 className='font-sans font-semibold text-4xl mb-1'>Search</h1>
                <h1 className='font-light font-sans text-xl mb-4'>View other lesson plans by educators</h1>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                            </svg>
                        </div>
                        <input disabled={true} value={searchQuery} onInputCapture={updateSearchQuery} type="search" id="default-search" class="font-light block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Middle school molecule" required />
                        <button disabled={true} onClick={searchLessons} type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Search</button>
                    </div>
                <p className="text-center text-sm font-light mt-4">Coming soon. Check back later!</p>
            </div>
        </div>
    )
}

export default SearchPage;