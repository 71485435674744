import React, { useState, useEffect, useRef } from 'react';

// Authentication components
import { useNavigate } from "react-router-dom";

function NavBar(){

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination)
    }

    const [isSidebarOpen, setSidebarOpen] = useState(false);
    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
      console.log(`sidebar toggled: ${!isSidebarOpen}`)
   };

    
    return(
        <div>

         {/* Mobile menu */}
         <button
            onClick={toggleSidebar}
            data-drawer-target="logo-sidebar" 
            data-drawer-toggle="logo-sidebar" 
            aria-controls="logo-sidebar" 
            type="button" 
            className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
         >
            {/* Accessibility text */}
            <span class="sr-only">Open sidebar</span>
            {/* Logo */}
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
               <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
            </svg>
         </button>

         {/* Desktop opened up menu */}
         <aside id="logo-sidebar" 
            className={`fixed top-0 left-0 z-40 h-screen transition-transform -translate-x-full md:translate-x-0 ${isSidebarOpen ? 'translate-x-0 w-[240px]' : '-translate-x-full w-64'} md:translate-x-0`}
            aria-label="Sidebar">
            <div class="h-full px-3 py-4 overflow-y-auto bg-gray-50">

               {/* Menu logo */}
               <a class="flex items-center pl-2.5 mb-5 mt-4 hover:cursor-pointer" onClick={() => navigateTo('/home')}>
                  <img src="https://i.imgur.com/fOR8pkx.png" class="h-6 mr-2 sm:h-7" alt="ClassroomGPT Logo" />
                  <span class="self-center text-xl font-bold whitespace-nowrap ">ClassroomGPT</span>
               </a>

               <ul class="space-y-2 font-medium">

                  {/* Home menu item */}
                  <li onClick={() => navigateTo('/home')}>
                     <a  class="hover:cursor-pointer flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                           <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">Home</span>
                     </a>
                  </li>

                  {/* Lesson menu item */}
                  <li onClick={() => navigateTo('/l')}>
                     <a  class="hover:cursor-pointer flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                           <path d="M16 14V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v15a3 3 0 0 0 3 3h12a1 1 0 0 0 0-2h-1v-2a2 2 0 0 0 2-2ZM4 2h2v12H4V2Zm8 16H3a1 1 0 0 1 0-2h9v2Z"/>
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">Lessons</span>
                     </a>
                  </li>

                  {/* Search menu item */}
                  <li onClick={() => navigateTo('/search')}>
                     <a class="hover:cursor-pointer flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100 group">

                        <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                           <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">Search</span>
                     </a>
                  </li>

                  {/* Account menu item */}
                  <li onClick={() => navigateTo('/account')}>
                     <a class="hover:cursor-pointer flex items-center p-2 text-gray-900 rounded-lg  hover:bg-gray-100  group">
                        <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75  group-hover:text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                           <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                        </svg>
                        <span class="flex-1 ml-3 whitespace-nowrap">Account</span>
                     </a>
                  </li>
               </ul>
            </div>
         </aside>

        </div>
    )
}
export default NavBar;