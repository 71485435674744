import React, { useState, useEffect, useRef } from 'react';
import { SSE } from "sse.js";
import posthog from 'posthog-js'

function FinalObjective({classDescription, academicStandard, audienceValue, behaviorValue, conditionValue, degreeValue, setFinalLearningObjective}){
    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [learningObjective, setLearningObjective] = useState('');
    const [disableButtons, setDisableButtons] = useState(false);
    const [mountCopy, setMountCopy] = useState(false);

    const finalLearningObjectiveRef = useRef();
    useEffect(() => {
        finalLearningObjectiveRef.current = learningObjective;
    }, [learningObjective]);

    function copyLearningObjective(){
        const textToCopy = learningObjective;
  
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch((error) => {
                console.error('Failed to copy text: ', error);
        });
    }

    function mountCopyButton(){
        if (mountCopy) {
            return (
                <div className='ml-6 mt-3'>
                    <button className="border border-gray border-solid ml-3" onClick={copyLearningObjective}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">content_copy</span>
                    </button>
                    <button className="border border-gray border-solid ml-3" onClick={generateFinalLearningObjective}>
                        <span class="material-symbols-outlined bg-white p-2 rounded-md text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">refresh</span>
                    </button>
                </div>
            )
        }  else {
            return
        }
    }

    function sendLearningObjectiveToFirebase(objective){
        setFinalLearningObjective(objective);
         posthog.capture('Learning Objective', { property: objective })
    }

    function generateFinalLearningObjective() {
        
         setLearningObjective('');
         setDisableButtons(true);
         setMountCopy(false);

         // 7-25-23 PROMPT INFO 
            //           \n\n Below are three examples of learning objectives for the cognitive, psychomotor, and affective domains:\n
            //   Cognitive: Given a poem, the student will be able to identify two instances where the poem author used figurative language.\n
            //   Psychomotor: During a scrimmage, the student will be able to complete an offensive screen that frees up the student’s teammate for a shot. \n
            //   Affective: By the end of the semester, the learner will be able to act on a belief they hold about a social cause they deem important to bring about change to their community.

        let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.`

        // Setting up the prompt
        if (classDescription === "") { classDescription = "K-12";}
        if (academicStandard !== "") {academicStandard = "creating a learning objective for the following academic standard:" + academicStandard;} 
        else {academicStandard = "creating a learning objective for a lesson I am preparing.";}

        /// OPENAI INFO
        const API_KEY = "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";
        /// END OF OPENAI INFO

        let descriptionPrompt = `I am a ${classDescription} educator ${academicStandard} I've defined the following parts:\n`
        let ABCDPrompt = `Audience: ${audienceValue}\n\nBehavior: ${behaviorValue}\n\nCondition: ${conditionValue}\n\nDegree: ${degreeValue}`;
        let finalPrompt = frameworkIntroduction + "\n\n" + descriptionPrompt + ABCDPrompt + "\n\nUse this information and your knowledge of ABCD framework, Bloom's Taxonomy, and learning objectives to write a one-sentence learning objective for my students:"
        console.log(finalPrompt)

         // #2: create request + stream response
        let data = { 
            model: "gpt-4-0613", 
            messages: [
                {"role": "user", "content": finalPrompt}
            ],
            temperature: 0.5,
            max_tokens: 500,
            stream: true
        }
        let source = new SSE("https://api.openai.com/v1/chat/completions", {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`
            },
            method: "POST",
            payload: JSON.stringify(data) 
        })

        const messageQueue = [];
        let processingMessage = false;
        let savingInputForCallback = "";

        source.addEventListener("message", (e) => {
            if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content; // 7-25 new field from chat endpoint
                if (!text){
                    text = "";
                }
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                savingInputForCallback = savingInputForCallback + text;
                } else {
                    source.close();
                setDisableButtons(false);
                setMountCopy(true);
                sendLearningObjectiveToFirebase(savingInputForCallback);
                }
            });

        function processNextMessage() {
            const text = messageQueue.shift();
            if (text) {
            let i = 0;
            processingMessage = true;
            const intervalId = setInterval(() => {
                if (i < text.length) {
                    const char = text.charAt(i);
                    if (char !== "\n") {
                    finalLearningObjectiveRef.current = finalLearningObjectiveRef.current + char;
                    setLearningObjective(savingInputForCallback);
                }
                    i++;
                } else {
                    clearInterval(intervalId);
                    processingMessage = false;
                    processNextMessage();
                }
            }, 15);
            }
        }

        source.addEventListener("readystatechange", (e) => {
            if (e.readyState >= 2) {}
        });
        source.stream()
    };

    return (
        <div className='w-full'>
            <div className='ml-14 mr-14 text-left'>
                {/* <h1 className='mt-4 text-xl font-normal text-[#272727] w-3/5 leading-6 mb-2'><span className='font-semibold'>Nice job!</span> Generate your final learning objective.</h1> */}
                <button disabled={disableButtons} onClick={generateFinalLearningObjective} className='bg-white px-3 py-2 border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md rounded-md'>Generate</button>
                <h1 className='mt-10 text-3xl font-light mx-10 text-left bg-white p-6 rounded-md'>{learningObjective}</h1>
                {mountCopyButton()}
                <div className='mb-12'></div>
            </div>
        </div>
    )
}

export default FinalObjective;