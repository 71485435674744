import React, { useState, useEffect } from "react";

function LOBreadcrumb({step}){

    let modifiedStep = 0;
    if (step === 1){
        modifiedStep = 1;
    } else if (step === 2) {
        modifiedStep = 1;
    } else {
        modifiedStep = step - 1;
    }

    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check the initial window size on mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return(
        <div className="">
            
            {
                !mobile  ? (
                    <div className="p-4">
                <ol class="items-center justify-center w-full space-y-4 md:flex md:space-x-8 md:space-y-0">

                    {/* Step #1: Intro */}
                    <li
                        className={`flex items-center ${
                        modifiedStep === 1 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}
                    >
                        <span
                        className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 1
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}
                        >
                            1
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Intro</h3>
                        
                        </span>
                    </li>

                    {/* Step #2: Audience */}
                    <li className={`flex items-center ${
                        modifiedStep === 2 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 2
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}>
                            2
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Audience</h3>
                            {/* <p class="text-sm">Step details here</p> */}
                        </span>
                    </li>

                    {/* Step #3: Behavior */}
                    <li className={`flex items-center ${
                        modifiedStep === 3 ? 'text-blue-600 dark:text-blue-500' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 3
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-'
                        }`}>
                            3
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Behavior</h3>
                        </span>
                    </li>

                    {/* Step #4: Condition */}
                    <li className={`flex items-center ${
                        modifiedStep === 4 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 4
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}>
                            4
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Condition</h3>
                        </span>
                    </li>

                    {/* Step #5: Degree */}
                    <li className={`flex items-center ${
                        modifiedStep === 5 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 5
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}>
                            5
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Degree</h3>
                        </span>
                    </li>

                    <li className={`flex items-center ${
                        modifiedStep === 6 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 6
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}>
                            6
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Final Objective</h3>
                        </span>
                    </li>

                    <li className={`flex items-center ${
                        modifiedStep === 7 ? 'text-blue-600' : 'text-gray-500'
                        } space-x-2.5`}>
                        <span className={`flex items-center justify-center w-8 h-8 border ${
                            modifiedStep === 7
                            ? 'border-blue-600 rounded-full shrink-0'
                            : 'border-gray-500 rounded-full shrink-0'
                        }`}>
                            7
                        </span>
                        <span>
                            <h3 class="font-medium leading-tight">Summary</h3>
                        </span>
                    </li>

                </ol>
            </div>
                ) : (
                    <div></div>
                )
            }
            
        </div>
    )
}

export default LOBreadcrumb;