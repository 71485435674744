import React, { useState } from 'react';
import FeedbackNavBar from "./FeedbackNavBar"
import { submitFeedback } from '../../Components/Authentication/Firebase';

function Feedback(){

    const [feedback, setFeedback] = useState('');
    const [error, setError] = useState('');

    function submitUserFeedback(){
        submitFeedback(feedback);
        setError('Success! Thank you for your feedback.')
    }

    function changeFeedbackValue(e){
        let feedback = e.target.value;
        setFeedback(feedback);
    }

    return(
        <div>
            <FeedbackNavBar />
            <div className='bg-[#FAF7F3] h-screen pt-10'>
                <h1 className='font-semibold text-2xl ml-10 mr-10'>Feedback</h1>
                <h1 className='font-normal text-lg ml-10 mr-10 mb-4'>Please leave any feedback you have about the site!</h1>
                <textarea value={feedback} onChange={changeFeedbackValue} className='min-h-12 max-h-24 ml-10 px-3 py-2 rounded-md w-1/2 border border-solid border-gray'></textarea>
                <div className='ml-10 mt-4'>
                    <button onClick={submitUserFeedback} className='bg-white text-black px-3 py-2 rounded-md border border-solid border-gray hover:shadow-md ease-in-out duration-200'>Submit</button>
                </div>
                <h1 className='ml-10 mt-4'>{error}</h1>
            </div>
        </div>
    )
}

export default Feedback;