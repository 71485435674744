import React, { useState, useRef, useEffect } from 'react';
import { SSE } from "sse.js";
import posthog from 'posthog-js'

function AudienceCard({classDescription, academicStandard, setAudienceValue}){
    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [audienceAnswer, setAudienceAnswer] = useState('');
    const [audienceFeedback, setAudienceFeedback] = useState('');
    const [audienceMessageHistory, setAudienceMessageHistory] = useState([])
    const [disableButtons, setDisableButtons] = useState(false);

    // References
    const audienceResultRef = useRef();
    useEffect(() => {
        audienceResultRef.current = audienceFeedback;
    }, [audienceFeedback]);

    /// PROMPT INFO
    // let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.
    // \n\nBelow are three examples of learning objectives for the cognitive, psychomotor, and affective domains:\n\nCognitive: Given a poem, the student will be able to identify two instances where the poem author used figurative language.\n\nPsychomotor: During a scrimmage, the student will be able to complete an offensive screen that frees up the student’s teammate for a shot.\n\nAffective: By the end of the semester, the learner will be able to act on a belief they hold about a social cause they deem important to bring about change to their community.`

    // 7-3-23 frameworkIntroduction
            //let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish. For example, you may want your students to be able to identify the main character in a story they have read. So, if you have them read a story, they would be able to identify who the main character is. How might they demonstrate that they can identify the main character? They could do this in many ways. They could verbally tell you if you asked them. They could write a short paragraph that indicates who the main character is. They could pick the main character out of a list of characters on an objective-type quiz that you have given students. These examples of how your students could demonstrate that they can identify the main character are call assessments. These assessments allow you to evaluate whether your students are able to meet the learning objective. An assessment must align directly with a learning objective."
            //let frameworkExamples = "Let’s look at two examples of learning objectives and then determine the different ABCD elements that make up the different objectives.\n\nLearning Objective #1: At the conclusion of the unit on basketball, students will be able to shoot a free throw with proper form.\nWith this objective, the audience is students. Their behavior is to shoot a free throw. The condition is at the end of the unit on basketball. The degree is with proper form. Students would demonstrate this behavior by actually shooting a free throw for the teacher to see. The shooting of the free throw would be the assessment. The measurement would be the actual free throw and if they made it and how well they did in using proper form. The evaluation would be to determine how well they shot the free throw and displayed the necessary elements of proper form.\n\nLearning Objective #2: After students have read the Great Gatsby, the students will be able to evaluate Fitzgerald’s use of metaphor.\nThe audience is the students. The behavior is to evaluate how Fitzgerald’s use of metaphor helped describe the complexities of the 1920s in the United States. The condition is after reading the Great Gatsby. The degree would be to how clearly the student evaluated how Fitzgerald’s use of metaphor helped describe the complexities of the 1920s in the United States."
            //frameworkIntroduction = frameworkIntroduction + frameworkExamples
            //let audienceGuidelines = "Audience, which is the A in ABCD, defines the intended audience for the learning objective. When writing Audience, an educator should consider the following: Who is the target audience? In other words, who will be expected to meet the learning objective? Your classroom students are generally the audience. So, for the purposes of an ABCD objective, you would indicate that your audience would be my learners, students, or learners. You could be more specific; for example, 8th-grade students, students in honors calculus, or students who need additional instruction. Typically, however, students or learners are how the audience is written."
    /// END OF PROMPT INFO

    // 7-25-23 new
    let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach."
    let audienceGuidelines = "Audience, which is the A in ABCD, defines the intended audience for the learning objective. When writing Audience, an educator should consider the following: Who is the target audience? In other words, who will be expected to meet the learning objective? Your classroom students are generally the audience. So, for the purposes of an ABCD objective, you would indicate that your audience would be my learners, students, or learners. You could be more specific; for example, 8th-grade students, students in honors calculus, or students who need additional instruction. Typically, however, students or learners are how the audience is written."
    let commonAudienceFailures = "When writing the Audience component of a learning objective, common traps to avoid include: defining an audience that is different from your class or intended audience and being too general about your audience."
    frameworkIntroduction = `${frameworkIntroduction}\n\n ${audienceGuidelines}\n\n${commonAudienceFailures}\n\n`
    /// END OF PROMPT INFO

    /// OPENAI INFO
    const API_KEY = "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";
    /// END OF OPENAI INFO

    function updateAudienceAnswer(e){
        const newValue = e.target.value;
        setAudienceAnswer(newValue);
    }

    function refreshAudienceAnswer(){
        setAudienceAnswer('');
    }

    function generateAudienceFeedback(){

        // If not filled, fill yourself
        if (classDescription === "") {classDescription = "K-12";}
        if (academicStandard === "") {academicStandard = "";}

        if (audienceAnswer === ""){
            return;
        }

        setDisableButtons(true);

        if (audienceMessageHistory.length === 0){

            // #1: Reset feedback, create new prompt
            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective for a lesson. The educator will submit their version of Audience and you, an instructional coach, will either 1. approve it and explain what they did well or 2. disapprove of it and explain what needs to be changed. Be constructive but succinct.\n\nEducator: For Audience, I will use: ${audienceAnswer}.\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${audienceGuidelines}\n\n${conversationContext}`
            setAudienceFeedback('');

            console.log(audienceMessageHistory);
            console.log(fullPrompt);

            setAudienceMessageHistory(prevMessages => [...prevMessages, 
                {"role": "user", "content": fullPrompt}
            ]);

            posthog.capture('Audience Input', { property: audienceAnswer })
            // TODO: error checking for classroom description and learning objective inputs

            // // #2: create request
            // let data = { model: "text-davinci-003", prompt: fullPrompt, temperature: 0.5, max_tokens: 500, stream: true };
            // // #3: stream response
            // let source = new SSE(openaiURL, {
            //     headers: {
            //     "Content-Type": "application/json",
            //     Authorization: `Bearer ${API_KEY}`,
            //     },
            //     method: "POST",
            //     payload: JSON.stringify(data),
            // });
            

            // BETA IMPLEMENTATION
            let data = { 
                model: "gpt-4-0613", 
                messages: [
                    {"role": "user", "content": fullPrompt}
                ],
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let source = new SSE("https://api.openai.com/v1/chat/completions", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${API_KEY}`
                },
                method: "POST",
                payload: JSON.stringify(data) 
            })


            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content;
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {

                    // THINGS TO DO WHEN STREAMING HAS ENDED
                    source.close();
                    setAudienceValue(audienceAnswer);
                    setDisableButtons(false);
                    posthog.capture('Audience Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        audienceResultRef.current = audienceResultRef.current + char;
                        setAudienceFeedback(audienceResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()

        } else {
            // #1: Reset feedback, update message history with the new prompt
            setAudienceMessageHistory(prevMessages => [...prevMessages,
                {"role": "assistant", "content": audienceFeedback}
            ]);
            let fullPrompt = "Educator: My new audience is: " + audienceAnswer + "\n\nInstructional Coach:"
            setAudienceMessageHistory(prevMessages => [...prevMessages,
                {"role": "user", "content": fullPrompt}
            ]);
            setAudienceFeedback("");
            posthog.capture('Audience Input', { property: audienceAnswer })

            // #1.5 Because state doesn't immediately updated, create copy of state to feed into API call
            let copiedAudienceMessageHistory = [...audienceMessageHistory];
            copiedAudienceMessageHistory.push({"role": "assistant", "content": audienceFeedback})
            copiedAudienceMessageHistory.push({"role": "user", "content": fullPrompt})

            // #2+3: create request and stream response
            console.log(copiedAudienceMessageHistory)
            let data = {
                model: "gpt-4-0613",
                messages: copiedAudienceMessageHistory,
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let chatEndpoint = "https://api.openai.com/v1/chat/completions"
            let source = new SSE(chatEndpoint, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`
            },
                method: "POST",
                payload: JSON.stringify(data)
            })

            
            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content;
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    source.close();
                    setAudienceValue(audienceAnswer);
                    setDisableButtons(false);
                    posthog.capture('Audience Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        audienceResultRef.current = audienceResultRef.current + char;
                        setAudienceFeedback(audienceResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()
        }
    }

    return(
        <div className='w-full'>
            <div className='ml-14 mr-14 text-left'>
                <h1 className='text-md font-normal text-[#272727] mb-1'>Audience*</h1>
                {/* <h1 className='text-sm font-light'>Write the audience for your learning objective.</h1> */}
                <input type="text" value={audienceAnswer} onChange={updateAudienceAnswer} className='font-light text-sm w-3/5 px-2 mb-1 py-2 border border-solid border-gray rounded-md' placeholder='My eighth grade biology students'></input>
                <br></br>
                <button disabled={disableButtons} className="mt-1 bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md" onClick={generateAudienceFeedback}>
                    <span class="material-symbols-outlined">check</span>
                </button>
                <button disabled={disableButtons} onClick={refreshAudienceAnswer} className="ml-2 bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">
                    <span class="material-symbols-outlined">refresh</span>
                </button>
                
            </div>
            <div className='flex flex-row justify-end ml-1 mt-8'>
                <h1 className='w-2/3 font-light ml-8 mt-8 mr-8'>{audienceFeedback}</h1>
                <img className="w-auto h-28 rounded-sm shadow-md mt-5 mb-5 mr-14" src={process.env.PUBLIC_URL + '/tim.jpg'} alt="Prof. Tim Green" ></img>
            </div>
        </div>
    )
}

export default AudienceCard;