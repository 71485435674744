import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// Authentication components
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';
import { getTeacherLessons, initializeNewLessonPlan } from '../../Components/Authentication/Firebase';

// Miscellaneous components
import LessonCards from './LessonCards';
import NavBar from './NavBar';
import PageSize from '../../Components/General/PageSize';
import LessonCard from './LessonCard';

// Styling
import "./Home.css";

// Analytics
import posthog from 'posthog-js'

function Home(){

    const [uid, setUid] = useState('');
    const [lessons, setLessons] = useState([]);
    const [name, setName] = useState('');

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }
    document.title = `Home • ClassroomGPT`


    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            setUid(signedIn.uid);
            if (signedIn.displayName === '' || signedIn.displayName === null){
                setName('');
            } else {
                setName(signedIn.displayName);
            }
            console.log(signedIn.displayName);
            posthog.identify(
                signedIn.uid,
                { email: signedIn.email, name: signedIn.name }
            );
            getTeacherLessons(signedIn.uid, (data) => {
                if (data === null || data === 'null') {
                    setLessons({})
                } else {
                    const jsonLessons= JSON.parse(data);
                    const lessonsArray = Object.values(jsonLessons);
                    if (lessonsArray.length > 0){
                        setLessons(lessonsArray);
                    }
                }
            });
        }
    }, [])


    function createNewLesson(){
        let newLessonId = initializeNewLessonPlan(uid);
        navigate(`/l/${newLessonId}`)
    }

    return(
        <div className='bg-[#F6F6F6] max-h-full min-h-screen'>
            <NavBar />
            <div class="p-4 md:ml-64 pt-14 pl-10 pr-10">
                <div class="p-4">
                    <h1 className='font-sans font-semibold text-4xl mb-1'>Home</h1>
                    {
                        name !== '' ? (
                            <h1 className='font-light font-sans text-xl mb-4'>Welcome to ClassroomGPT, {name}!</h1>
                        ) : (
                            <h1 className='font-light font-sans text-xl mb-4'>Welcome to ClassroomGPT!</h1>
                        )
                    }
                </div>
                <div class="grid grid-cols-1 gap-4 mb-4">
            <div onClick={() => navigateTo('/l')} class="flex items-center justify-center rounded bg-gray-50 duration-200 ease-in-out hover:bg-gray-200 h-28 hover:cursor-pointer hover:shadow-md">
                <p class="text-2xl text-gray-700 text-center">Create a new lesson</p>
            </div>
            {/* <div onClick={() => navigateTo('/search')} class="flex items-center justify-center rounded bg-gray-50 duration-200 ease-in-out hover:bg-blue-50 h-28 hover:cursor-pointer hover:shadow-md">
                <p className='text-2xl text-gray-700'>Get inspiration</p>
            </div> */}
        </div>
            </div>
            
        
            
            {/* <PageSize threshold={820} message="Please use ClassroomGPT on a laptop or desktop device for the best experience!" /> */}
        </div>
    
    )
}

export default Home;