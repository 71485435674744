import React, { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';

// Auth components
import SignInWithGoogle from '../../Components/Authentication/SignInWithGoogle';
import SignInWithMicrosoft from '../../Components/Authentication/SignInWithMicrosoft';
import SignInWithCredentials from '../../Components/Authentication/SignInWithCredentials';
import { useNavigate } from "react-router-dom";


// Styling
import './Landing.css';

function Landing(){

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    const [mobile, setMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Check the initial window size on mount

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    if (!mobile){
        return(
            <div className={`flex h-screen`}>
                
                {/* Login/signup area */}
                <div className='w-1/2 flex flex-col items-center justify-center bg-[#F6F6F6]'>
                    <h1 className='text-4xl text-center font-normal mb-4 font-serif'>Create your account</h1>
                    <SignInWithGoogle />
                    <SignInWithMicrosoft />
                    <h3 className='mt-8 mb-8 text-md font-normal relative'>
                        <span className='px-1'>
                            or
                        </span>
                    </h3>
                    <SignInWithCredentials />
                </div>
                
                {/* ClassroomGPT hero information section */}
                <div className='w-1/2 flex flex-col items-center justify-center bg-[#2CA58D]'>
                    <h1 className='text-white text-6xl font-normal tracking-wide font-serif'>ClassroomGPT</h1>
                    <h2 className='text-white text-2xl font-light mt-2 font-sans'>A teacher's best friend in the classroom</h2>
                    <ul className='text-white text-lg font-light mt-6 list-disc list-inside leading-8 font-sans'>
                        <li>Create end-to-end lesson plans</li>
                        <li>Share resources with colleagues</li>
                        <li>Search other lessons for inspiration</li>
                    </ul>
                    <div className='mt-20'></div>
                    <div className='fixed bottom-2 text-white font-sans font-light'>
                        <div className='flex row justify-center items-center'>
                            <h1 onClick={() => navigateTo('/tos')} className='hover:text-gray-100 hover:cursor-pointer duration-200 ease-in-out'>Terms of use</h1>
                            <h1 className='mx-2'> | </h1>
                            <h1 onClick={() => navigateTo('/privacy')} className='hover:text-gray-100 hover:cursor-pointer duration-200 ease-in-out'>Privacy policy</h1>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return(
            <div className={""}>
                
                {/* Login/signup area */}
                <h1 className='text-black text-4xl font-normal tracking-wide font-serif text-center mt-32'>ClassroomGPT</h1>
                <h2 className='text-black text-xl font-light mt-2 font-sans text-center'>A teacher's best friend in the classroom</h2>
                <div className='flex flex-col items-center justify-center mt-10'>
                    <SignInWithGoogle />
                    <SignInWithMicrosoft />
                    <h3 className='mt-8 mb-8 text-md font-normal relative'>
                        <span className='px-1'>
                            or
                        </span>
                    </h3>
                    <SignInWithCredentials />
                </div>
            </div>
        )
    }
}

export default Landing;