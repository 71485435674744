import React, { useState } from "react";
import { useNavigate} from "react-router-dom";

function BuilderNavBar({sendLessonTitle, isLearningObjectiveBuilder}){

    const navigate = useNavigate('');
    const [lessonTitle, setLessonTitle] = useState('');
    
    function navigateHome(){
        navigate('/home');
    }

    function updateLessonTitle(e){
        const title = e.target.value;
        setLessonTitle(title);
        sendLessonTitle(title);
    }
    
    return(
        <div className='flex flex-row justify-between h-16 w-screen items-center bg-white'>
            {/* Home icon*/}
            <div>
                <button className="hover:cursor-pointer" onClick={navigateHome}>
                    <div className="ml-14">
                        <span class="material-symbols-outlined text-3xl text-gray-600 hover:text-gray-900 ease-in-out duration-200">home</span>
                    </div>
                </button>
            </div>

            {/* Lesosn title and edit */}
            {isLearningObjectiveBuilder ? (
                <div className='flex flex-row justify-start'>
                    <input 
                        onChange={updateLessonTitle} 
                        className="font-light text-2xl text-black w-4/5 focus:border-none focus:outline-none" 
                        placeholder="Default lesson title" 
                        value={lessonTitle}
                    />
                    <span className="material-symbols-outlined text-3xl text-gray-400">edit</span>
                </div>
            ) : null}

            <div>
                
            </div>
        </div>
    )
}

export default BuilderNavBar;