import React from 'react';

function ReviewerIntro(){
    return(
        <div className='w-full'>
            {/* <h1 className='text-xl font-normal text-center ml-32 mr-32'>Great, let’s move on to the feedback phase. In the next 4 steps, you will write out each component of your learning objective, submit your work, and receive feedback.</h1> */}
            <div className='flex justify-center'>
                <img className="w-auto h-32 mt-6 mb-4 rounded-sm shadow-md" src={process.env.PUBLIC_URL + '/tim.jpg'} alt="Prof. Tim Green"></img>
            </div>
            <h1 className='text-lg font-normal text-center ml-32 mr-32 mt-4'>Today, you will receive feedback from Prof. Green.</h1>
        </div>
    )
}

export default ReviewerIntro;