import React, { useState, useEffect } from "react";
import NavBar from '../Home/NavBar';
import { useNavigate } from "react-router-dom";
import { checkAuthStatus } from '../../Components/Authentication/AuthDetails';
import { auth } from '../../Components/Authentication/Firebase';
import { signOut } from 'firebase/auth';

function AccountPage(){

    // need to make this a fucking component
    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    document.title = `Account • ClassroomGPT`

    const [showEmail, setShowEmail] = useState('');
    function emailSam(){
        setShowEmail('sam@classroomgpt.app')
        const emailAddress = 'sam@classroomgpt.app';
        const mailtoLink = `mailto:${emailAddress}`;
        window.location.href = mailtoLink;
    }

    function signUserOut(){
        signOut(auth).then(()=> {
        console.log('signed out successfully')
        navigateTo('/')
        
        }).catch((error) => {
            console.log(error)
        })
    }

    const [uid, setUid] = useState('');
    useEffect(() => {
        const signedIn = checkAuthStatus();
        if (signedIn === null){
            navigateTo('/')
        } else {
            setUid(signedIn.uid);
        }
    }, [])

    return(
        <div className='bg-[#F6F6F6] max-h-full min-h-screen'>
            <NavBar />
            <div class="p-4 md:ml-64 pt-14 pl-10 pr-10">
                <h1 className='font-sans font-semibold text-4xl mb-1'>Account</h1>

                {/* p-4 max-w-fit rounded-lg border border-dashed border-gray-600  */}
                {/* border-dashed border-gray-700 border */}
                <div className="mt-8 rounded-lg">  
                    <h1 className="font-normal text-xl">Account information</h1>
                    <p className="font-light mb-2">Information and configuration settings</p>
                    <div class="inline-flex rounded-md shadow-sm" role="group">
                <button onClick={signUserOut} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                    <svg class="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M7 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm2 1H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                    </svg>
                    Sign Out
                </button>
                {/* <button type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-r border-gray-200 rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                    <svg class="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4a4 4 0 0 1 4 4v6M5 4a4 4 0 0 0-4 4v6h8M5 4h9M9 14h10V8a3.999 3.999 0 0 0-2.066-3.5M9 14v5m0-5h4v5m-9-8h2m8-4V1h2"/>
                    </svg>
                    Reset password
                </button> */}

                </div>
                </div>

                <div className="mt-8 rounded-lg">
                    <h1 className="font-normal text-xl">Help</h1>
                    <p className="font-light mb-2">Account help, feature requests, etc.</p>
                    <div class="inline-flex rounded-md shadow-sm" role="group">
                        <button onClick={emailSam} type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-l-lg rounded-r-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700">
                            <svg class="w-3 h-3 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 4a4 4 0 0 1 4 4v6M5 4a4 4 0 0 0-4 4v6h8M5 4h9M9 14h10V8a3.999 3.999 0 0 0-2.066-3.5M9 14v5m0-5h4v5m-9-8h2m8-4V1h2"/>
                            </svg>
                            Contact Sam
                        </button>
                    </div>
                    <p className="mt-1 font-light text-sm">{showEmail}</p>
                </div>


            </div>
        </div>
    )
}

export default AccountPage;