import React, { useState, useEffect, useRef } from 'react';
import { SSE } from "sse.js";

function AssessmentBrainstorm({previousAssessmentTechniques, successfulAssessments, resourcesForLesson, learningObjective, classroomDescription}){


    const [generatedAssessment, setGeneratedAssessment] = useState('This is the placeholder assessment. Select refresh to generate your first assessment.');
    const [messageHistory, setMessageHistory] = useState([]);
    const [usersAssessmentFeedback, setUsersAssessmentFeedback] = useState('')

    // OLD PROMPT
    function renderCard(){
        
        const prompt = `
Learning Objective: ${learningObjective}
Previous assessments that the educator has attempted: ${previousAssessmentTechniques}
Previous assessments that have worked in the past: ${successfulAssessments}
Resources available for lesson: ${resourcesForLesson}

Above is information from an educator looking to brainstorm an assessment for their lesson plan. The ${classroomDescription} educator has provided the learning objective for the lesson, information about their previous assessments, and the resources at their disposal for the lesson.

Using this information, generate an assessment idea that specifies:  assessment method, estimated cost, length of assessment, rubric, and any other relevant details. When creating this lesson, be creative but within the constraints of the educator's class and their learning objective. Keep the idea to 2-3 paragraphs maximum.`
    }

    // References
    const assessmentResultRef = useRef();
    useEffect(() => {
        assessmentResultRef.current = generatedAssessment;
    }, [generatedAssessment]);


    // New assessment idea
    function refreshAssessment(){

        setGeneratedAssessment('');
        setMessageHistory([]);

        // Build prompt
        let assessmentIntro = `In K-12 education, an assessment refers to procedures or techniques used to obtain data from a learner. The main purpose of assessment is to help determine if a student has been able to meet a learning objective. The assessment should clearly allow us to determine the performance of a student. Determining what the assessment should be begins with the learning objective.`
        let learningObjectiveRelevance = `The learning objective will indicate what the learner should be able to know or do (i.e., the Behavior). In other words, the learning objective will indicate the outcome we are expecting from the learner. Let’s look at an example of a learning objective to determine the outcome. At the end of the training session, the learner will be able to list three major warning signs of a heart attack. The Behavior is to list. More specifically, the behavior is to list three major warning signs of a heart attack. If a learner can list the three major warning signs of a heart attack, the learner will have met the intended outcome that the learning objective describes. Often, the learning objective provides a specific indication of what the assessment should be. Let’s look at an example with the following learning objective. At the end of the semester when given a topic, the student will be able to write a haiku. The haiku poem that the student wrote would be the assessment.`
        let additionalAssessmentDetails = `Typical assessments are tests and quizzes. Although these may be appropriate depending on the learning objective, test and quizzes should not be the only type of assessment used. A rubric is not an assessment. A rubric is an evaluation tool. A rubric helps us determine how well a student has done on an assessment.`
        let userInputs = `The following is information provided by a K-12 teacher:\nLearning objective: ${learningObjective.description}\nPrevious approaches: ${previousAssessmentTechniques}\nSuccess of those approaches: ${successfulAssessments}\nResources for lesson: ${resourcesForLesson}`
        let directions = `You are a K-12 teacher educator. Using the above information, write a 3-4 sentence assessment description that accurately measures the teacher's learning objective. Structure your assessement with the following fields: title, description, materials needed, and why you chose it. Write <BOLD> opening and closing tags around each title so I know which elements to bold.`
        let finalPrompt = `${assessmentIntro}\n\n${learningObjectiveRelevance}\n\n${additionalAssessmentDetails}\n\n${userInputs}\n\n${directions}`
        setMessageHistory(prevMessages => [...prevMessages, 
                {"role": "user", "content": finalPrompt}
        ]);
        

        // Build request to OpenAI
        const API_KEY = "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";
        let data = { model: "gpt-4-0613", messages: [{"role": "user", "content": finalPrompt}], temperature: 0.9, max_tokens: 500, stream: true}
        let source = new SSE("https://api.openai.com/v1/chat/completions", {
            headers: {"Content-Type": "application/json", Authorization: `Bearer ${API_KEY}`},
            method: "POST",
            payload: JSON.stringify(data) 
        })


        // Process streamed response
        const messageQueue = [];
        let processingMessage = false;
        let savingInputForCallback = "";

        source.addEventListener("message", (e) => {
            if (e.data !== "[DONE]") {
            
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content;
                console.log(text)
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                savingInputForCallback = savingInputForCallback + text;
                } else {
                    // THINGS TO DO WHEN STREAMING HAS ENDED
                    source.close();
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                            assessmentResultRef.current = assessmentResultRef.current + char;
                            setGeneratedAssessment(assessmentResultRef.current);
                        } else {
                            assessmentResultRef.current = assessmentResultRef.current + '\n';
                            setGeneratedAssessment(assessmentResultRef.current);
                        }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()
    }

    // Handler for user input
    function handleUserFeedback(event){
        setUsersAssessmentFeedback(event.target.value);
    }


    function handleEnterKey(event){
        if (event.key === 'Enter'){
            submitAssessmentFeedback();
            setUsersAssessmentFeedback('');
            console.log('enter')
        }
    }

    // Give specific feedback
    function submitAssessmentFeedback(){
        

        // #1: Reset feedback, update message history with the new prompt
        setMessageHistory(prevMessages => [...prevMessages,
            {"role": "assistant", "content": generatedAssessment}
        ]);
        let fullPrompt = "Educator: This is my feedback about your assessment: " + usersAssessmentFeedback + "\n\nDirections: Now, rewrite the assessment:"
        setMessageHistory(prevMessages => [...prevMessages,
            {"role": "user", "content": fullPrompt}
        ]);

        setUsersAssessmentFeedback("");
        setGeneratedAssessment('');

        // #1.5 Because state doesn't immediately updated, create copy of state to feed into API call
        let copiedAudienceMessageHistory = [...messageHistory];
        copiedAudienceMessageHistory.push({"role": "assistant", "content": generatedAssessment})
        copiedAudienceMessageHistory.push({"role": "user", "content": fullPrompt})


        // #2+3: create request and stream response
        const API_KEY = "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";
        console.log(copiedAudienceMessageHistory)
        let data = {
            model: "gpt-4-0613",
            messages: copiedAudienceMessageHistory,
            temperature: 0.90,
            max_tokens: 500,
            stream: true
        }
        let chatEndpoint = "https://api.openai.com/v1/chat/completions"
        let source = new SSE(chatEndpoint, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${API_KEY}`
        },
            method: "POST",
            payload: JSON.stringify(data)
        })

        // #4: stream response
        const messageQueue = [];
        let processingMessage = false;
        let savingInputForCallback = "";

        source.addEventListener("message", (e) => {
            if (e.data !== "[DONE]") {
            let payload = JSON.parse(e.data);
            let text = payload.choices[0].delta.content;
            messageQueue.push(text);
            if (!processingMessage) {
                processNextMessage();
            }
                savingInputForCallback = savingInputForCallback + text;
            } else {
                source.close();
            }
        });

        function processNextMessage() {
            const text = messageQueue.shift();
            if (text) {
            let i = 0;
            processingMessage = true;
            const intervalId = setInterval(() => {
                if (i < text.length) {
                    const char = text.charAt(i);
                    if (char !== "\n") {
                        assessmentResultRef.current = assessmentResultRef.current + char;
                        setGeneratedAssessment(assessmentResultRef.current);
                    } else {
                        assessmentResultRef.current = assessmentResultRef.current + '\n';
                        setGeneratedAssessment(assessmentResultRef.current);
                    }
                    i++;
                } else {
                    clearInterval(intervalId);
                    processingMessage = false;
                    processNextMessage();
                }
            }, 15);
            }
        }

        source.addEventListener("readystatechange", (e) => {
            if (e.readyState >= 2) {}
        });
        source.stream()

    }

    const processedContent = generatedAssessment.split(/(<BOLD>.*?<\/BOLD>)/g).map((part, index) => {
    if (part.startsWith('<BOLD>') && part.endsWith('</BOLD>')) {
      const text = part.substring(6, part.length - 7);
      return <span key={index} style={{ fontWeight: 'bold' }}>{text}</span>;
    }
    return part;
  });

    return(
        <div className='mt-10 ml-10 mr-10'>
            <div className='flex flex-col justify-center items-center'>
                {/* Generated assessment */}
                <div className='bg-white rounded-md w-1/2 mb-6 p-8 whitespace-pre-line border border-solid boder-gray-300'>
                    {processedContent}
                </div>
                
                {/* Menu options */}
                <div className='flex flex-row justify-between w-1/2'>
                    <div className='w-3/4'>
                        <input 
                            className='p-2 text-md rounded-md w-full border border-solid border-gray-200' 
                            placeholder='Incorporate technology'
                            onChange={handleUserFeedback}
                            onKeyDown={handleEnterKey}
                            value={usersAssessmentFeedback}
                        ></input>
                    </div>
                    <div className='flex flex-row'>
                        <button
                        onClick={submitAssessmentFeedback} 
                        className='mr-2 pt-2 px-2 bg-white rounded-md border border-solid border-gray-300 hover:shadow-md duration-200 ease-in-out'
                    >
                        <span class="material-symbols-outlined">
                            check
                        </span>
                    </button>
                    <button
                        onClick={refreshAssessment} 
                        className='pt-2 px-2 bg-white rounded-md border border-solid border-gray-300 hover:shadow-md duration-200 ease-in-out'
                    >
                        <span class="material-symbols-outlined">
                            refresh
                        </span>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AssessmentBrainstorm;