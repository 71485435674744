import React, { useState } from 'react';
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";
import { Navigate } from "react-router-dom";
import './SignIn.css';

function SignInWithMicrosoft(){

    const [error, setError] = useState('');
    const [signedIn, setSignedIn] = useState(false);
    const provider = new OAuthProvider('microsoft.com');
    provider.setCustomParameters({
        prompt: "consent",
    })
    const auth = getAuth();
    function signMeIn(){
        setError('');
        signInWithPopup(auth, provider)
        .then((result) => {
            // User is signed in.
            // IdP data available in result.additionalUserInfo.profile.

            // Get the OAuth access token and ID Token
            const credential = OAuthProvider.credentialFromResult(result);
            const accessToken = credential.accessToken;
            const idToken = credential.idToken;
            console.log('success')
            setSignedIn(true);
        })
        .catch((error) => {
            // Handle error.
            console.log(error);
            console.log('ERROR')
            setError('Unknown error. Please try again.')
        });
    }

    if (signedIn){
        return <Navigate to="/home" />;
    }

    return (
        <div className='mt-2 max-w-fit'>
            <div onClick={signMeIn} className='hover:cursor-pointer border border-solid border-gray rounded-md bg-white px-7 py-2 flex flex-row justify-center items-center hover:bg-[#f9f9f9] ease-in-out duration-200' type="button">
                <img className='w-5 h-5 mr-3' src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/512px-Microsoft_logo.svg.png?20210729021049" alt="Google Logo"></img>
                <h5 className='opacity-80 text-md'>Continue with Microsoft</h5>
            </div>
            {/* <div>
                <h1> this is a test that I want to comment out </h1>
            </div> */}
            <div className='text-center text-red-900 mt-1 mb-2 font-light'>
                {error}
            </div>
        </div>
    )
}

export default SignInWithMicrosoft;