import React, { useState, useRef, useEffect } from 'react';
import { SSE } from "sse.js";
import posthog from 'posthog-js'

function BehaviorCard({classDescription, academicStandard, audienceValue, setBehaviorValue}){
    posthog.init('phc_mw8xb5Oejz31Lwy3eap0xYmnVm9DRQTMvG1Xi3YiVVZ', { api_host: 'https://app.posthog.com' })

    const [behaviorAnswer, setBehaviorAnswer] = useState('');
    const [behaviorFeedback, setBehaviorFeedback] = useState('');
    const [behaviorMessageHistory, setBehaviorMessageHistory] = useState([])
    const [disableButtons, setDisableButtons] = useState(false);

    // References
    const behaviorResultRef = useRef();
    useEffect(() => {
        behaviorResultRef.current = behaviorFeedback;
    }, [behaviorFeedback]);

    /// 7-3-23 PROMPT INFO
    // let frameworkIntroduction = `A learning objective is a statement that describes what learners will be expected to know or be able to do by the end of a lesson/unit of instruction/project/course/semester/school year. A well-written learning objective describes the intended learning outcome. A main purpose of learning objectives is to help educators in the design and development of effective and efficient instructional experiences for their learners. A well-written learning objective provides guidance for an educator (and their learners) about what is expected of the learners. Who, what, when, and at what level are described in a well-written learning objective. The ABCD framework is a way of structuring learning objectives for instructional material.
    // \n\nBelow are three examples of learning objectives for the cognitive, psychomotor, and affective domains:\n\nCognitive: Given a poem, the student will be able to identify two instances where the poem author used figurative language.\n\nPsychomotor: During a scrimmage, the student will be able to complete an offensive screen that frees up the student’s teammate for a shot.\n\nAffective: By the end of the semester, the learner will be able to act on a belief they hold about a social cause they deem important to bring about change to their community.`
    
    /// 7-25-23 PROMPT INFO
    //let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish. For example, you may want your students to be able to identify the main character in a story they have read. So, if you have them read a story, they would be able to identify who the main character is. How might they demonstrate that they can identify the main character? They could do this in many ways. They could verbally tell you if you asked them. They could write a short paragraph that indicates who the main character is. They could pick the main character out of a list of characters on an objective-type quiz that you have given students. These examples of how your students could demonstrate that they can identify the main character are call assessments. These assessments allow you to evaluate whether your students are able to meet the learning objective. An assessment must align directly with a learning objective."
    //let behaviorGuidelines = "Behavior, which is the B in ABCD, refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material. When writing Behavior, an educator should consider the following: What is the outcome of the learners participating in the instruction?  In other words, what is the audience expected to know or be able to do based on engaging in the instruction? This needs to be written as a verb (this is the “behavior”). What you expect your learners to know or be able to do (the verb) must be observable. It must also be measurable. There are three major domains that a behavior could fall into. These are the cognitive domain, psychomotor domain, or the affective domain.  The behavior will only be in one of the three domains. The cognitive domain focuses on intellectual activity such as thinking, remembering, reasoning, and evaluating.  Historically, Bloom’s Taxonomy has been used to describe the behaviors that fit as part of the cognitive domain. Verbs that fit within this domain range from lower-level thinking processes (e.g., remembering) to higher-level thinking processes (e.g., synthesizing, evaluating, judging, creating). The psychomotor domain focuses on physical functions, actions, and movements. Verbs that fit within this domain range from the level of imitation (e.g., copy or replicate an action) to the levels of articulation (e.g., adapt, integrate, modify) and naturalization (e.g., automatic actions, intuitive responses). The affective domain focuses on emotions and feelings. This domain is the most difficult to create learning objectives that can be measured. Verbs that fit with this domain range from the receiving level (e.g., feel, sense, experience) to the characterization level (e.g., conclude, internalize, resolve).  You need to include only one verb. The verb must be something that can be measured.";
    
    /// PROMPT INFO
    let frameworkIntroduction = "A learning objective is what you want your students to know or be able to do once they have participated in instruction. A learning objective is the foundation of a well-written lesson plan. One of the most direct and effective ways to write a learning objective is to use the ABCD approach. This approach makes sure that you focus on one verb (known as a behavior) that you want your students to be able to accomplish."
    let behaviorGuidelines = "Behavior, which is the B in ABCD, refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material. When writing Behavior, an educator should consider the following: What is the outcome of the learners participating in the instruction?  In other words, what is the audience expected to know or be able to do based on engaging in the instruction? This needs to be written as a verb (this is the “behavior”). What you expect your learners to know or be able to do (the verb) must be observable. It must also be measurable. There are three major domains that a behavior could fall into. These are the cognitive domain, psychomotor domain, or the affective domain.  The behavior will only be in one of the three domains. The cognitive domain focuses on intellectual activity such as thinking, remembering, reasoning, and evaluating.  Historically, Bloom’s Taxonomy has been used to describe the behaviors that fit as part of the cognitive domain. Verbs that fit within this domain range from lower-level thinking processes (e.g., remembering) to higher-level thinking processes (e.g., synthesizing, evaluating, judging, creating). The psychomotor domain focuses on physical functions, actions, and movements. Verbs that fit within this domain range from the level of imitation (e.g., copy or replicate an action) to the levels of articulation (e.g., adapt, integrate, modify) and naturalization (e.g., automatic actions, intuitive responses). The affective domain focuses on emotions and feelings. This domain is the most difficult to create learning objectives that can be measured. Verbs that fit with this domain range from the receiving level (e.g., feel, sense, experience) to the characterization level (e.g., conclude, internalize, resolve).  You need to include only one verb. The verb must be something that can be measured.";
    let commonBehaviorFailures = "When writing the Behavior component of a learning objective, common traps to avoid include: using two verbs to describe the behavior. For example: 'Students will be able to design and implement' uses two verbs instead of focusing on one. If you need to use two verbs, break it into two separated learning objectives."
    frameworkIntroduction = `${frameworkIntroduction}\n\n ${behaviorGuidelines}\n\n${commonBehaviorFailures}\n\n`
    /// END OF PROMPT INFO

    /// OPENAI INFO
    const API_KEY = "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";
    /// END OF OPENAI INFO

    function updateBehaviorAnswer(e){
        const newValue = e.target.value;
        setBehaviorAnswer(newValue);
    }

    function refreshBehaviorAnswer(){
        setBehaviorAnswer('');
    }

    function generateBehaviorFeedback(){

        // If not filled, fill yourself
        if (classDescription === "") {classDescription = "K-12";}
        if (academicStandard === "") {academicStandard = "for a lesson";}
        else {academicStandard = `on the following standard: ${academicStandard}`}

        if (behaviorAnswer === ""){return;}
        setDisableButtons(true);


        if (behaviorMessageHistory.length === 0){

            // #1: Reset feedback, create new prompt
            let conversationContext = `The following is a conversation with a ${classDescription} educator creating a learning objective ${academicStandard}. The educator will submit their version of Behavior and you, an instructional coach, will either 1. approve it with feedback or 2. disapprove of it with feedback. Be constructive but succinct.\n\nEducator: My Audience is: ${audienceValue}. For Behavior, I want to use: "${behaviorAnswer}".\n\nInstructional Coach:`;
            let fullPrompt = `${frameworkIntroduction}\n\n${behaviorGuidelines}\n\n${conversationContext}`
            console.log(fullPrompt)
            setBehaviorFeedback('');

            posthog.capture('Behavior Input', { property: behaviorAnswer })
            setBehaviorMessageHistory(prevMessages => [...prevMessages, 
                {"role": "user", "content": fullPrompt}
            ]);

            // #2: create request + stream response
            let data = { 
                model: "gpt-4-0613", 
                messages: [
                    {"role": "user", "content": fullPrompt}
                ],
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let source = new SSE("https://api.openai.com/v1/chat/completions", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${API_KEY}`
                },
                method: "POST",
                payload: JSON.stringify(data) 
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content;
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    // THINGS TO DO WHEN STREAMING HAS ENDED
                    source.close();
                    setBehaviorValue(behaviorAnswer);
                    setDisableButtons(false);
                    posthog.capture('Behavior Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        behaviorResultRef.current = behaviorResultRef.current + char;
                        setBehaviorFeedback(behaviorResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()

        } else {


            // #1: Reset feedback, update message history with the new prompt
            setBehaviorMessageHistory(prevMessages => [...prevMessages,
                {"role": "assistant", "content": behaviorFeedback}
            ]);
            let fullPrompt = "Educator: My new behavior is: " + behaviorAnswer + "\n\nInstructional Coach:"
            setBehaviorMessageHistory(prevMessages => [...prevMessages,
                {"role": "user", "content": fullPrompt}
            ]);
            setBehaviorFeedback("");
            posthog.capture('Behavior Input', { property: behaviorAnswer })

            // #1.5 Because state doesn't immediately updated, create copy of state to feed into API call
            let copiedBehaviorMessageHistory = [...behaviorMessageHistory];
            copiedBehaviorMessageHistory.push({"role": "assistant", "content": behaviorFeedback})
            copiedBehaviorMessageHistory.push({"role": "user", "content": fullPrompt})


            // #2+3: create request and stream response
            console.log(copiedBehaviorMessageHistory)
            let data = {
                model: "gpt-4-0613",
                messages: copiedBehaviorMessageHistory,
                temperature: 0.5,
                max_tokens: 500,
                stream: true
            }
            let chatEndpoint = "https://api.openai.com/v1/chat/completions"
            let source = new SSE(chatEndpoint, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${API_KEY}`
            },
                method: "POST",
                payload: JSON.stringify(data)
            })

            const messageQueue = [];
            let processingMessage = false;
            let savingInputForCallback = "";

            source.addEventListener("message", (e) => {
                if (e.data !== "[DONE]") {
                let payload = JSON.parse(e.data);
                let text = payload.choices[0].delta.content;
                messageQueue.push(text);
                if (!processingMessage) {
                    processNextMessage();
                }
                    savingInputForCallback = savingInputForCallback + text;
                } else {
                    source.close();
                    setBehaviorValue(behaviorAnswer);
                    setDisableButtons(false);
                    posthog.capture('Behavior Feedback', { property: savingInputForCallback })
                }
            });

            function processNextMessage() {
                const text = messageQueue.shift();
                if (text) {
                let i = 0;
                processingMessage = true;
                const intervalId = setInterval(() => {
                    if (i < text.length) {
                        const char = text.charAt(i);
                        if (char !== "\n") {
                        behaviorResultRef.current = behaviorResultRef.current + char;
                        setBehaviorFeedback(behaviorResultRef.current);
                    }
                        i++;
                    } else {
                        clearInterval(intervalId);
                        processingMessage = false;
                        processNextMessage();
                    }
                }, 15);
                }
            }

            source.addEventListener("readystatechange", (e) => {
                if (e.readyState >= 2) {}
            });
            source.stream()
        }
    }

    return (
        <div className='w-full'>
            <div className='ml-14 mr-14 text-left'>
                {/* <h1 className='mt-4 text-xl font-normal text-[#272727] w-3/5 leading-6 mb-2'><b className='text-[#073F74] font-semibold'>Behavior.</b> Behavior refers to the observable and measurable actions or skills that learners will demonstrate as a result of the instructional material.</h1> */}
                <h1 className='text-md font-normal text-[#272727] mb-1'>Behavior*</h1>
                <textarea value={behaviorAnswer} onChange={updateBehaviorAnswer} className='font-light text-sm max-h-24 w-3/5 px-2 py-2 border border-solid border-gray rounded-md' placeholder='Outline the atomic composition of three complex organic molecules'></textarea>
                <br></br>
                <button disabled={disableButtons} className="bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md" onClick={generateBehaviorFeedback}>
                    <span class="material-symbols-outlined">check</span>
                </button>
                <button disabled={disableButtons} onClick={refreshBehaviorAnswer} className="ml-2 bg-white pt-2 px-2 rounded-md border border-solid border-gray text-gray-600 hover:text-gray-900 ease-in-out duration-200 hover:drop-shadow-md">
                    <span class="material-symbols-outlined">refresh</span>
                </button>
                
            </div>
            <div className='flex flex-row justify-end ml-1 mt-8'>
                <h1 className='w-2/3 font-light ml-8 mt-8 mr-8'>{behaviorFeedback}</h1>
                <img className="w-auto h-28 rounded-sm shadow-md mt-5 mb-5 mr-14" src={process.env.PUBLIC_URL + '/tim.jpg'} alt="Prof. Tim Green" ></img>
            </div>
        </div>
    )
}

export default BehaviorCard;