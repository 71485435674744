import React, { useState, useEffect } from "react";
import ABreadcrumb from "./ABreadcrumb";
import { useNavigate } from "react-router-dom";
import OpenAI from "openai";

import { 
    assessmentSlide2,
    assessmentSlide3,
    assessmentSlide4,
    assessmentSlide5,
    assessmentSetPreFormativeSummativeAssessment,
    assessmentSetPreAssessmentFeedback,
    assessmentSetFormativeAssessmentFeedback,
    assessmentSetSummativeAssessmentFeedback,
    assessmentSetFinished

} from "../../../Components/Authentication/Firebase";

function ACoordinator({lessonId, uid}){

    const [step, setStep] = useState(1);

    const navigate = useNavigate('');
    function navigateTo(destination){
        navigate(destination);
    }

    function updateStep(direction){
        if (direction === 'forward'){
            if (step === 5){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step + 1);
            }
        } else if (direction === 'back'){
            if (step === 1){
                navigateTo(`/l/${lessonId}`)
            } else {
                setStep(step - 1);
            }
        } else {
            return;
        }
    }

    let content;
    if (step === 1) {
        content = <AStepOne stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 2) {
        content = <AStepTwo stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 3) {
        content = <AStepThree stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 4){
        content = <AStepFour stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else if (step === 5){
        content = <AStepFive stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    } else {
        content = <AStepOne stepChangeCallback={updateStep} lessonId={lessonId} userId={uid}/>;
    }

    return(
        <div className="relative">
            {content}
            <div className="fixed bottom-0 left-0 w-full z-50 bg-white">
                <ABreadcrumb step={step} />
            </div>
        </div>
    )
}

export default ACoordinator;

export function AStepOne({stepChangeCallback, userId, lessonId}){

    const [isPreChecked, setIsPreChecked] = useState(false);
    const [isFormativeChecked, setIsFormativeChecked] = useState(false);
    const [isSummativeChecked, setIsSummativeChecked] = useState(false);
    const [direction, setDirection] = useState('');
    
    function handlePreChange(){
        setIsPreChecked(!isPreChecked);
    }

    function handleFormativeChange(){
        setIsFormativeChecked(!isFormativeChecked);
    }

    function handleSummativeChange(){
        setIsSummativeChecked(!isSummativeChecked);
    }

    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            if (isPreChecked === false & isFormativeChecked === false & isSummativeChecked & false){
                setDirection('Please select at least one assessment to work on.');
                return;
            } else {
                assessmentSetPreFormativeSummativeAssessment(userId, lessonId, isPreChecked, isFormativeChecked, isSummativeChecked);
                stepChangeCallback(direction);
            }
        } else {
            return;
        }
    }

    return(
        <div className='mr-32'>
            <h5 className="font-semibold text-2xl">Creating Assessment</h5>
            <p className="font-light">In the assessment preparation, we will evaluate which types of assessments you will use in this lesson and brainstorm the best ways to design and administer these assessments.</p>
            

            <h3 class="mb-4 text-lg font-medium text-gray-900 mt-6">Select which assessments you'd like to use in the lesson:</h3>
            <ul class="grid w-full gap-3 md:grid-rows-3">
                <li>
                    <input onChange={handlePreChange} checked={isPreChecked} type="checkbox" id="preassessment-option" value="" class="hidden peer" required="" />
                    <label for="preassessment-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 hover:text-gray-600 peer-checked:text-gray-600 hover:bg-gray-50">                           
                        <div class="block">
                            <div class="w-full text-lg font-semibold">Pre-Assessment</div>
                            <div class="w-full text-sm">A bell-ringer, flipped classroom homework, or pre-lesson evaluation tool.</div>
                        </div>
                    </label>
                </li>
                <li>
                    <input onChange={handleFormativeChange} checked={isFormativeChecked} type="checkbox" id="formative-option" value="" class="hidden peer" />
                    <label for="formative-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 hover:text-gray-600 peer-checked:text-gray-600 hover:bg-gray-50">
                        <div class="block">
                            <div class="w-full text-lg font-semibold">Formative Assessment</div>
                            <div class="w-full text-sm">A mid-lecture quiz, Kahoot, or other tool.</div>
                        </div>
                    </label>
                </li>
                <li>
                    <input onChange={handleSummativeChange} checked={isSummativeChecked} type="checkbox" id="summative-option" value="" class="hidden peer" />
                    <label for="summative-option" class="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 hover:text-gray-600 peer-checked:text-gray-600 hover:bg-gray-50">
                        <div class="block">
                            <div class="w-full text-lg font-semibold">Summative Assessment</div>
                            <div class="w-full text-sm">A post-lesson quiz, essay, assignment, project, exit form, or other tool.</div>
                        </div>
                    </label>
                </li>
            </ul>
            <div className="mt-4">
                <p className="text-sm font-light">{direction}</p>
            </div>

            
            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function AStepTwo({stepChangeCallback, userId, lessonId}){

    const [isLoading, setIsLoading] = useState(true);
    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [context, setContext] = useState([])

    // const [academicStandard, setAcademicStandard] = useState('');
    // const [classroomDescription, setClassroomDescription] = useState('');
    // const [learningObjective, setLearningObjective] = useState('')
    // const [learnerNeeds, setLearnerNeeds] = useState('');
    // const [identifiedStandard, setIdentifiedStandard] = useState('');

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    async function onRenderCallback(preAssessmentSet, academicStandard, classroomDescription, learningObjective, learnerNeeds, identifiedStandard){

        // Step #0: Check whether or not user selected pre-assessmen
        console.log(`Is the pre-assessment set? ${preAssessmentSet}`);
        if (!preAssessmentSet){
            stepChangeCallback('forward');
        } else {
            // setAcademicStandard(academicStandard);
            // setClassroomDescription(classroomDescription);
            // setLearningObjective(learningObjective);
            // setLearnerNeeds(learnerNeeds);
            // setIdentifiedStandard(identifiedStandard);
        }

        // Step #1: Build first message
        let instructorIntroductionprompt = `You are an instructional designer for K-12 schools. You are collaborating with an educator with the following characteristics:\n Classroom description: ${classroomDescription}\nAcademic standard:${academicStandard}\n\n`
        let preLessonPrompt = `Using this information, you helped the educator define the following details:\n Learner needs: ${learnerNeeds}\nIdentified standard:${identifiedStandard}.\n\n`
        let LOPrompt = `With this, you helped the educator design the following learning objective: ${learningObjective}.\n\n`;
        let prompt = `${instructorIntroductionprompt}${preLessonPrompt}${LOPrompt}The educator has decided to design a pre-assessment for the lesson. Using this information, write a 3-4 sentence specification for a pre-assessment the user can do before the lesson:`
        let firstMessage = {role: 'system', content: prompt};

        // Step #2: Generate first response
            // "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",
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [firstMessage],
        });

        // Step #3: Add response to context
        let secondMessage = completion.choices[0].message
        const newContext = [...context, firstMessage, secondMessage];
        setContext(newContext);

        // Step #4: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my understanding, you can move to the next section.')

    }

    async function submitUserResponse(){
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        // Step #1: Housekeeping before rendering new response
        let feedbackForPrompt = userFeedback;
        setUserFeedback('');
        setIsLoading(true);

        // Step #2: Build next prompt
        let nextPrompt = feedbackForPrompt; // customize later on
        let nextMessage = {role: 'user', content: nextPrompt}

        // Step #3: Generate next response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [...context, nextMessage],
        });

        // Step #4: Add response to context
        let nextNextMessage = completion.choices[0].message
        const newContext = [...context, nextMessage, nextNextMessage];
        setContext(newContext);

        // Step #5: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

    }

    useEffect(() => {
        assessmentSlide2(userId, lessonId, onRenderCallback);
    }, [])


    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // #1: Save analyzed learner need to firebase before continuing
            assessmentSetPreAssessmentFeedback(userId, lessonId, currentResponse);

            // #2: Navigate forward
            stepChangeCallback(direction);

        } else {
            return;
        }
    }

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Brainstorming a Pre-Assessment</h5>
            <p className="font-light leading-5 mt-1">Based on what we decided in the last two steps, here is an idea for a pre-assessment for this lesson:</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between h-full">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }


            {/* Navigation menu */}
            <div class="flex mt-12">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )

}

export function AStepThree({stepChangeCallback, userId, lessonId}){

    const [isLoading, setIsLoading] = useState(true);
    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [context, setContext] = useState([]);

    const [academicStandard, setAcademicStandard] = useState('');
    const [classroomDescription, setClassroomDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('')
    const [learnerNeeds, setLearnerNeeds] = useState('');
    const [identifiedStandard, setIdentifiedStandard] = useState('');

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    async function onRenderCallback(formativeAssessmentSet, academicStandard, classroomDescription, learningObjective, learnerNeeds, identifiedStandard){

        // User did not select pre-assessment, so continue
        console.log(`Is the formative set? ${formativeAssessmentSet}`);
        if (!formativeAssessmentSet){
            stepChangeCallback('forward');
        } else {
            setAcademicStandard(academicStandard);
            setClassroomDescription(classroomDescription);
            setLearningObjective(learningObjective);
            setLearnerNeeds(learnerNeeds);
            setIdentifiedStandard(identifiedStandard);
        }

        // Step #1: Build first message
        let instructorIntroductionprompt = `You are an instructional designer for K-12 schools. You are collaborating with an educator with the following characteristics:\n Classroom description: ${classroomDescription}\nAcademic standard:${academicStandard}\n\n`
        let preLessonPrompt = `Using this information, you helped the educator define the following details:\n Learner needs: ${learnerNeeds}\nIdentified standard:${identifiedStandard}.\n\n`
        let LOPrompt = `With this, you helped the educator design the following learning objective: ${learningObjective}.\n\n`;
        let prompt = `${instructorIntroductionprompt}${preLessonPrompt}${LOPrompt}The educator has decided to design a formative assessment to teach somewhere in the lesson. Using this information, write a 3-4 sentence specification for a formative assessment the teacher can use in their lesson:`
        let firstMessage = {role: 'system', content: prompt};

        // Step #2: Generate first response
            // "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",
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [firstMessage],
        });

        // Step #3: Add response to context
        let secondMessage = completion.choices[0].message
        const newContext = [...context, firstMessage, secondMessage];
        setContext(newContext);

        // Step #4: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my understanding, you can move to the next section.')

    }

    async function submitUserResponse(){
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        // Step #1: Housekeeping before rendering new response
        let feedbackForPrompt = userFeedback;
        setUserFeedback('');
        setIsLoading(true);

        // Step #2: Build next prompt
        let nextPrompt = feedbackForPrompt; // customize later on
        let nextMessage = {role: 'user', content: nextPrompt}

        // Step #3: Generate next response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [...context, nextMessage],
        });

        // Step #4: Add response to context
        let nextNextMessage = completion.choices[0].message
        const newContext = [...context, nextMessage, nextNextMessage];
        setContext(newContext);

        // Step #5: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

    }

    useEffect(() => {
        assessmentSlide3(userId, lessonId, onRenderCallback);
    }, [])


    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {

            // #1: Save analyzed learner need to firebase before continuing
            assessmentSetFormativeAssessmentFeedback(userId, lessonId, currentResponse);

            // #2: Navigate forward
            stepChangeCallback(direction);

        } else {
            return;
        }
    }

    return(
         <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Brainstorming a Formative Assessment</h5>
            <p className="font-light leading-5 mt-1">Based on what we decided in the last two parts, here is an idea for a formative assessment for this lesson:</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between h-full">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }


            {/* Navigation menu */}
            <div class="flex mt-12">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function AStepFour({stepChangeCallback, userId, lessonId}){

    const [isLoading, setIsLoading] = useState(true);
    const [currentResponse, setCurrentResponse] = useState('');
    const [userFeedback, setUserFeedback] = useState('');
    const [instruction, setInstruction] = useState('');
    const [context, setContext] = useState([]);

    const [academicStandard, setAcademicStandard] = useState('');
    const [classroomDescription, setClassroomDescription] = useState('');
    const [learningObjective, setLearningObjective] = useState('')
    const [learnerNeeds, setLearnerNeeds] = useState('');
    const [identifiedStandard, setIdentifiedStandard] = useState('');

    function onUserFeedbackUpdate(event){
        setUserFeedback(event.target.value);
    }

    async function onRenderCallback(summativeAssessmentSet, academicStandard, classroomDescription, learningObjective, learnerNeeds, identifiedStandard){

        // User did not select pre-assessment, so continue
        console.log(`Is the summative assessment set? ${summativeAssessmentSet}`);
        if (!summativeAssessmentSet){
            stepChangeCallback('forward');
        } else {
            setAcademicStandard(academicStandard);
            setClassroomDescription(classroomDescription);
            setLearningObjective(learningObjective);
            setLearnerNeeds(learnerNeeds);
            setIdentifiedStandard(identifiedStandard);
        
             // Step #1: Build first message
            let instructorIntroductionprompt = `You are an instructional designer for K-12 schools. You are collaborating with an educator with the following characteristics:\n Classroom description: ${classroomDescription}\nAcademic standard:${academicStandard}\n\n`
            let preLessonPrompt = `Using this information, you helped the educator define the following details:\n Learner needs: ${learnerNeeds}\nIdentified standard:${identifiedStandard}.\n\n`
            let LOPrompt = `With this, you helped the educator design the following learning objective: ${learningObjective}.\n\n`;
            let prompt = `${instructorIntroductionprompt}${preLessonPrompt}${LOPrompt}The educator has decided to design a summative assessment to administer somewhere after the lesson. Using this information, write a 3-4 sentence specification for a summative assessment the teacher can use after their lesson:`
            let firstMessage = {role: 'system', content: prompt};

            // Step #2: Generate first response
                // "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",
            const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
            const completion = await openai.chat.completions.create({
                model: "gpt-3.5-turbo",
                messages: [firstMessage],
            });

            // Step #3: Add response to context
            let secondMessage = completion.choices[0].message
            const newContext = [...context, firstMessage, secondMessage];
            setContext(newContext);

            // Step #4: Render response to user
            let newResponse = completion.choices[0].message.content;
            setCurrentResponse(newResponse);
            setIsLoading(false);

            // Step #5: Render guidelines message
            setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied with my understanding, you can move to the next section.')

        
        }
    }

    async function submitUserResponse(){
        if (userFeedback === ''){
            setInstruction('To submit feedback, please enter it in the above input. If you are satisfied with my response, you can proceed.');
            return;
        }

        // Step #1: Housekeeping before rendering new response
        let feedbackForPrompt = userFeedback;
        setUserFeedback('');
        setIsLoading(true);

        // Step #2: Build next prompt
        let nextPrompt = feedbackForPrompt; // customize later on
        let nextMessage = {role: 'user', content: nextPrompt}

        // Step #3: Generate next response
        const openai = new OpenAI({apiKey: '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', dangerouslyAllowBrowser: true});
        const completion = await openai.chat.completions.create({
            model: "gpt-3.5-turbo",
            messages: [...context, nextMessage],
        });

        // Step #4: Add response to context
        let nextNextMessage = completion.choices[0].message
        const newContext = [...context, nextMessage, nextNextMessage];
        setContext(newContext);

        // Step #5: Render response to user
        let newResponse = completion.choices[0].message.content;
        setCurrentResponse(newResponse);
        setIsLoading(false);

        // Step #5: Render guidelines message
        setInstruction('Agree? Disagree? How should I adjust this? Once you are satisfied, you can move to the next section.')

    }

    useEffect(() => {
        assessmentSlide4(userId, lessonId, onRenderCallback);
    }, [])


    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            
            // #1: Save analyzed learner need to firebase before continuing
            assessmentSetSummativeAssessmentFeedback(userId, lessonId, currentResponse);

            // #2: Navigate forward
            stepChangeCallback(direction);
            
        } else {
            return;
        }
    }

    return(
         <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Brainstorming a Summative Assessment</h5>
            <p className="font-light leading-5 mt-1">Based on what we decided in the last two parts, here is an idea for a summative assessment for this lesson:</p>
            {
                isLoading === true ? (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <div class="flex items-center justify-center w-full h-full">
                                <div role="status">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        
                        {/* User responses */}
                        <div class="mb-6">
                            <input type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                        </div>
                    </div>
                ) : (
                    <div>
                        {/* System response canvas */}
                        <div className="w-full h-48 bg-gray-50 rounded-md my-6 border border-gray-300">
                            <p className="py-4 px-6">{currentResponse}</p>
                        </div>
                        
                        {/* User response */}
                        <div class="mb-2 flex flex-row justify-between h-full">
                            <input value={userFeedback} onInputCapture={onUserFeedbackUpdate} type="text" id="default-input" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" />
                            <button onClick={submitUserResponse} type="button" class=" ml-2 mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none">Submit</button>
                        </div>
                        <p className="font-light text-sm mb-6">{instruction}</p>
                    </div>
                )
            }


            {/* Navigation menu */}
            <div class="flex mt-12">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )
}

export function AStepFive({stepChangeCallback, userId, lessonId}){

    function moveFromStep(direction){
        if (direction === "back"){
            stepChangeCallback(direction);
        } else if (direction === "forward") {
            stepChangeCallback(direction);
        } else {
            return;
        }
    }

    const [preAssessment, setPreAssessment] = useState('');
    const [formativeAssessment, setFormativeAssessment] = useState('');
    const [summativeAssessment, setSummativeAsssessment] = useState('');

    const [isPreTrue, setIsPreTrue] = useState(false);
    const [isFormativeTrue, setIsFormativeTrue] = useState(false);
    const [isSummativeTrue, setIsSummativeTrue] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    function onRenderCallback(isPreTrue, isFormativeTrue, isSummativeTrue, preAssessment, formativeAssessment, summativeAssessment){
        
        // Get assignment values from Firebase
        setPreAssessment(preAssessment);
        setFormativeAssessment(formativeAssessment);
        setSummativeAsssessment(summativeAssessment);
        setIsPreTrue(isPreTrue);
        setIsFormativeTrue(isFormativeTrue);
        setIsSummativeTrue(isSummativeTrue);

        // Cease loading
        setIsLoading(false);

        // Set assessment as finished
        assessmentSetFinished(userId, lessonId);
    
    }

    useEffect(() => {
        assessmentSlide5(userId, lessonId, onRenderCallback);
    }, [])

    return(
        <div className='mr-20'>
            <h5 className="font-semibold text-2xl">Summary</h5>
            <p className="font-light leading-5 mt-1">Let's review what we covered in our assessment preparation.</p>

            {
                isLoading === true ? (
                    <div>
                        <div class="flex items-center justify-center w-full h-full">
                                <div role="status" className="mt-10">
                                    <svg aria-hidden="true" class="w-8 h-8 mr-2 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/><path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/></svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                    </div>
                ) : (
                    <div className="mt-6">
                        {
                            isPreTrue ? (
                                <div>
                                    <h1 className="font-medium text-xl pb-1">Pre-Assessment</h1>
                                    <p className="pb-3 font-light text-md">{preAssessment}</p>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                        {
                            isFormativeTrue ? (
                                <div>
                                    <h1 className="font-medium text-xl pb-1">Formative Assessment</h1>
                                    <p className="pb-3 font-light text-md">{formativeAssessment}</p>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                        {
                            isSummativeTrue ? (
                                <div>
                                    <h1 className="font-medium text-xl pb-1">Summative Assessment</h1>
                                    <p className="pb-3 font-light text-md">{summativeAssessment}</p>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                    </div>
                )
            }

            <div class="flex mt-6">
                <a onClick={() => moveFromStep('back')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Previous
                </a>
                <a onClick={() => moveFromStep('forward')} class="hover:cursor-pointer flex items-center justify-center px-3 h-8 ml-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700">
                    Next
                </a>
            </div>
        </div>
    )

}